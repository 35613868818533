import React, { useEffect, useState } from "react";
import InfiniteTabInput from "../../../../../../Components/GlobalComponents/InfiniteTabInput/InfiniteTabInput";
import InfiniteInputComponent from "../../InfiniteInputComponent/InfiniteInputComponent";
import StyledTextInputField from "../../../../../../GlobalComponents/InputField/StyledTextInputField/StyledTextInputField";
import StyledDateInputField from "../../../../../../GlobalComponents/InputField/StyledDateInputField/StyledDateInputField";
import StyledPhoneInputField from "../../../../../../GlobalComponents/InputField/StyledPhoneInputField/StyledPhoneInputField";
import SelectField from "../../../../../../GlobalComponents/InputField/SelectField/SelectField";
import {
  formatSSN,
  isValidCountry,
} from "../../../../../../GlobalFunctions/globalFunctions";
import useDelayUnmount from "../../../../../../CustomHooks/useDelayUnmount";
import TrusteeSection from "./TrusteeSection/TrusteeSection";
import { isDobValid } from "../../../../../../Functions/functions";
import { isEmailValid } from "../../../../../../Functions/functions";
import { isValidSSN } from "../../../../../../Functions/functions";
import GrantorInformation from "./TrusteeSection/GrantorInformation/GrantorInformation";
import { updateRelatedPartyInformation } from "../../../../../../Api/ProfileApi";
import { useHistory } from "react-router";
import BeneficialOwnerSection from "./BeneficialOwnerSection/BeneficialOwnerSection";
import { stripOutDigits } from "../../../../../../utilities/functions/functions";

function TrustAccountRelatedPartyInformation({
  accountType,
  unmountedStyle,
  mountedStyle,
  processedCountryList,
  processedStateList,
  setActiveSlide,
  accountID,
  supplyPercentOwned,
}) {
  let history = useHistory();
  const [hasTrusteesError, setHasTrusteesError] = useState("");
  const [grantorFirstName, setGrantorFirstName] = useState("");
  const [grantorFirstNameError, setGrantorFirstNameError] = useState("");

  const [grantorLastName, setGrantorLastName] = useState("");
  const [grantorLastNameError, setGrantorLastNameError] = useState("");

  const [grantorEmail, setGrantorEmail] = useState("");
  const [grantorEmailError, setGrantorEmailError] = useState("");

  const [grantorPhoneNumber, setGrantorPhoneNumber] = useState("");
  const [grantorPhoneNumberError, setGrantorPhoneNumberError] = useState("");

  const [grantorDomicileCountry, setGrantorDomicileCountry] = useState("");
  const [grantorDomicileCountryError, setGrantorDomicileCountryError] =
    useState("");

  const [grantorAddressLine1, setGrantorAddressLine1] = useState("");
  const [grantorAddressLine1Error, setGrantorAddressLine1Error] = useState("");

  const [grantorAddressLine2, setGrantorAddressLine2] = useState("");
  const [grantorAddressLine2Error, setGrantorAddressLine2Error] = useState("");

  const [grantorCity, setGrantorCity] = useState("");
  const [grantorCityError, setGrantorCityError] = useState("");

  const [grantorState, setGrantorState] = useState("");
  const [grantorStateError, setGrantorStateError] = useState("");

  const [grantorCountry, setGrantorCountry] = useState("");
  const [grantorCountryError, setGrantorCountryError] = useState("");

  const [grantorPostalCode, setGrantorPostalCode] = useState("");
  const [grantorPostalCodeError, setGrantorPostalCodeError] = useState("");

  const [grantorDOB, setGrantorDOB] = useState("");
  const [grantorDOBError, setGrantorDOBError] = useState("");

  const [grantorIDType, setGrantorIDType] = useState("");
  const [grantorIDTypeError, setGrantorIDTypeError] = useState("");

  const [grantorIDNumber, setGrantorIDNumber] = useState("");
  const [grantorIDNumberError, setGrantorIDNumberError] = useState("");

  const [grantorPercentOwned, setGrantorPercentOwned] = useState("");
  const [grantorPercentOwnedError, setGrantorPercentOwnedError] = useState("");

  const [grantorCitizenship, setGrantorCitizenship] = useState("");
  const [grantorCitizenshipError, setGrantorCitizenshipError] = useState("");

  const [hasAlternateSignatory, setHasAlternateSignatory] = useState("");
  const [hasAlternateSignatoryError, setHasAlternateSignatoryError] =
    useState("");
  const [alternateSignatoryFullName, setAlternateSignatoryFullName] =
    useState("");
  const [alternateSignatoryFullNameError, setAlternateSignatoryFullNameError] =
    useState("");
  const [alternateSignatoryEmail, setAlternateSignatoryEmail] = useState("");
  const [alternateSignatoryEmailError, setAlternateSignatoryEmailError] =
    useState("");
  const [alternateSignatoryTitle, setAlternateSignatoryTitle] = useState("");
  const [alternateSignatoryTitleError, setAlternateSignatoryTitleError] =
    useState("");

  const [relatedAccounts, setRelatedAccounts] = useState([
    {
      relatedPartyType: "",
      relatedPartyTypeError: "",
      entityPercentOwned: "",
      entityPercentOwnedError: "",
      entityName: "",
      entityNameError: "",
      entityAddressLine1: "",
      entityAddressLine1Error: "",
      entityAddressLine2: "",
      entityAddressLine2Error: "",
      entityCity: "",
      entityCityError: "",
      entityState: "",
      entityStateError: "",
      entityPostalCode: "",
      entityPostalCodeError: "",
      entityTaxIDNumber: "",
      entityTaxIDNumberError: "",
      entityPhoneNumber: "",
      entityPhoneNumberError: "",
      entityStateOfIncorporation: "",
      entityStateOfIncorporationError: "",
      entityMailingAddressDifferent: "",
      entityMailingAddressDifferentError: "",
      entityMailingAddressLine1: "",
      entityMailingAddressLine1Error: "",
      entityMailingAddressLine2: "",
      entityMailingAddressLine2Error: "",
      entityMailingCity: "",
      entityMailingCityError: "",
      entityMailingState: "",
      entityMailingStateError: "",
      entityMailingPostalCode: "",
      entityMailingPostalCodeError: "",
      entityType: "",
      entityTypeError: "",
      entityControlPersonFirstName: "",
      entityControlPersonFirstNameError: "",
      entityControlPersonLastName: "",
      entityControlPersonLastNameError: "",
      entityControlPersonEmail: "",
      entityControlPersonEmailError: "",
      entityControlPersonIsUSBased: "",
      entityControlPersonIsUSBasedError: "",
      entityControlPersonIsUSCitizen: "",
      entityControlPersonIsUSCitizenError: "",
      entityControlPersonPIIAvailable: "",
      entityControlPersonPIIAvailableError: "",
      entityControlPersonAddressLine1: "",
      entityControlPersonAddressLine1Error: "",
      entityControlPersonAddressLine2: "",
      entityControlPersonAddressLine2Error: "",
      entityControlPersonCity: "",
      entityControlPersonCityError: "",
      entityControlPersonState: "",
      entityControlPersonStateError: "",
      entityControlPersonPostalCode: "",
      entityControlPersonPostalCodeError: "",
      entityControlPersonPhoneNumber: "",
      entityControlPersonPhoneNumberError: "",
      entityControlPersonTaxIDNumber: "",
      entityControlPersonTaxIDNumberError: "",
      entityControlPersonDOB: "",
      entityControlPersonDOBError: "",
      entityCertificateOfFormation: "",
      entityCertificateOfFormationError: "",
      entityProofOfAddress: "",
      entityProofOfAddressError: "",
      entityControlPersonPassportDocument: "",
      entityControlPersonPassportDocumentError: "",
      entityControlPersonProofOfAddress: "",
      entityControlPersonProofOfAddressError: "",
      entityIsTrustManagementCompany: "",
      entityIsTrustManagementCompanyError: "",
      entityHasAlternateSignatory: "",
      entityHasAlternateSignatoryError: "",
      entityAlternateSignatoryFullName: "",
      entityAlternateSignatoryFullNameError: "",
      entityAlternateSignatoryEmail: "",
      entityAlternateSignatoryEmailError: "",
      entityAlternateSignatoryTitle: "",
      entityAlternateSignatoryTitleError: "",
      entityControlPersonRole: "",
      entityControlPersonRoleError: "",
      entityNestedControlEntityTrusteeType: "",
      entityNestedControlEntityTrusteeTypeError: "",
      entityNestedControlEntityName: "",
      entityNestedControlEntityNameError: "",
      entityNestedControlEntityType: "",
      entityNestedControlEntityTypeError: "",
      entityNestedControlEntityPercentOwned: "",
      entityNestedControlEntityPercentOwnedError: "",
      entityNestedControlEntityStateOfIncorporation: "",
      entityNestedControlEntityStateOfIncorporationError: "",
      entityNestedControlEntityTaxIDNumber: "",
      entityNestedControlEntityTaxIDNumberError: "",
      entityNestedControlEntityPhoneNumber: "",
      entityNestedControlEntityPhoneNumberError: "",
      entityNestedControlEntityAddressLine1: "",
      entityNestedControlEntityAddressLine1Error: "",
      entityNestedControlEntityAddressLine2: "",
      entityNestedControlEntityAddressLine2Error: "",
      entityNestedControlEntityCity: "",
      entityNestedControlEntityCityError: "",
      entityNestedControlEntityState: "",
      entityNestedControlEntityStateError: "",
      entityNestedControlEntityPostalCode: "",
      entityNestedControlEntityPostalCodeError: "",
      entityNestedControlEntityIsTrustManagementCompany: "",
      entityNestedControlEntityIsTrustManagementCompanyError: "",
      entityNestedControlEntityAddressType: "",
      entityNestedControlEntityAddressTypeError: "",
      entityNestedControlEntityCertificateOfFormation: "",
      entityNestedControlEntityCertificateOfFormationError: "",
      entityNestedControlEntityProofOfAddress: "",
      entityNestedControlEntityProofOfAddressError: "",
      entityNestedControlEntityControlPersonFirstName: "",
      entityNestedControlEntityControlPersonFirstNameError: "",
      entityNestedControlEntityControlPersonLastName: "",
      entityNestedControlEntityControlPersonLastNameError: "",
      entityNestedControlEntityControlPersonEmail: "",
      entityNestedControlEntityControlPersonEmailError: "",
      entityNestedControlEntityControlPersonIsUSBased: "",
      entityNestedControlEntityControlPersonIsUSBasedError: "",
      entityNestedControlEntityControlPersonIsUSCitizen: "",
      entityNestedControlEntityControlPersonIsUSCitizenError: "",
      entityNestedControlEntityControlPersonPhoneNumber: "",
      entityNestedControlEntityControlPersonPhoneNumberError: "",
      entityNestedControlEntityControlPersonPIIAvailable: "",
      entityNestedControlEntityControlPersonPIIAvailableError: "",
      entityNestedControlEntityControlPersonAddressLine1: "",
      entityNestedControlEntityControlPersonAddressLine1Error: "",
      entityNestedControlEntityControlPersonAddressLine2: "",
      entityNestedControlEntityControlPersonAddressLine2Error: "",
      entityNestedControlEntityControlPersonCity: "",
      entityNestedControlEntityControlPersonCityError: "",
      entityNestedControlEntityControlPersonState: "",
      entityNestedControlEntityControlPersonStateError: "",
      entityNestedControlEntityControlPersonPostalCode: "",
      entityNestedControlEntityControlPersonPostalCodeError: "",
      entityNestedControlEntityControlPersonTaxIDNumber: "",
      entityNestedControlEntityControlPersonTaxIDNumberError: "",
      entityNestedControlEntityControlPersonDOB: "",
      entityNestedControlEntityControlPersonDOBError: "",
      entityNestedControlEntityControlPersonPassportDocument: "",
      entityNestedControlEntityControlPersonPassportDocumentError: "",
      entityNestedControlEntityControlPersonProofOfAddress: "",
      entityNestedControlEntityControlPersonProofOfAddressError: "",
      entityNestedControlEntityControlPersonAddressType: "",
      entityNestedControlEntityControlPersonAddressTypeError: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      country: "",
      postalCode: "",
      addressType: "",
      deliverableAddressLine1: "",
      deliverableAddressLine2: "",
      deliverableCity: "",
      deliverableState: "",
      deliverableCountry: "",
      deliverablePostalCode: "",
      deliverableAddressType: "",
      dateOfBirth: "",
      dateOfBirthError: "",
      idType: "",
      idNum: "",
      idNumError: "",
      domicileCountry: "",
      firstNameError: "",
      lastNameError: "",
      emailError: "",
      phoneNumberError: "",
      addressLine1Error: "",
      addressLine2Error: "",
      cityError: "",
      stateError: "",
      countryError: "",
      postalCodeError: "",
      deliverableAddressLine1Error: "",
      deliverableAddressLine2Error: "",
      deliverableCityError: "",
      deliverableStateError: "",
      deliverableCountryError: "",
      deliverablePostalCodeError: "",
      primaryAddressDeliverable: "",
      domicileCountryError: "",
      deliverableSelectionError: "",
      percentOwned: "",
      percentOwnedError: "",
      citizenship: "",
      citizenshipError: "",
    },
  ]);

  const [beneficialOwnerRelatedAccounts, setBeneficialOwnerRelatedAccounts] =
    useState([
      {
        relatedPartyType: "",
        relatedPartyTypeError: "",
        entityPercentOwned: "",
        entityPercentOwnedError: "",
        entityName: "",
        entityNameError: "",
        entityAddressLine1: "",
        entityAddressLine1Error: "",
        entityAddressLine2: "",
        entityAddressLine2Error: "",
        entityCity: "",
        entityCityError: "",
        entityState: "",
        entityStateError: "",
        entityPostalCode: "",
        entityPostalCodeError: "",
        entityTaxIDNumber: "",
        entityTaxIDNumberError: "",
        entityPhoneNumber: "",
        entityPhoneNumberError: "",
        entityStateOfIncorporation: "",
        entityStateOfIncorporationError: "",
        entityMailingAddressDifferent: "",
        entityMailingAddressDifferentError: "",
        entityMailingAddressLine1: "",
        entityMailingAddressLine1Error: "",
        entityMailingAddressLine2: "",
        entityMailingAddressLine2Error: "",
        entityMailingCity: "",
        entityMailingCityError: "",
        entityMailingState: "",
        entityMailingStateError: "",
        entityMailingPostalCode: "",
        entityMailingPostalCodeError: "",
        entityType: "",
        entityTypeError: "",
        entityControlPersonFirstName: "",
        entityControlPersonFirstNameError: "",
        entityControlPersonLastName: "",
        entityControlPersonLastNameError: "",
        entityControlPersonEmail: "",
        entityControlPersonEmailError: "",
        entityControlPersonIsUSBased: "",
        entityControlPersonIsUSBasedError: "",
        entityControlPersonIsUSCitizen: "",
        entityControlPersonIsUSCitizenError: "",
        entityControlPersonPIIAvailable: "",
        entityControlPersonPIIAvailableError: "",
        entityControlPersonAddressLine1: "",
        entityControlPersonAddressLine1Error: "",
        entityControlPersonAddressLine2: "",
        entityControlPersonAddressLine2Error: "",
        entityControlPersonCity: "",
        entityControlPersonCityError: "",
        entityControlPersonState: "",
        entityControlPersonStateError: "",
        entityControlPersonPostalCode: "",
        entityControlPersonPostalCodeError: "",
        entityControlPersonPhoneNumber: "",
        entityControlPersonPhoneNumberError: "",
        entityControlPersonTaxIDNumber: "",
        entityControlPersonTaxIDNumberError: "",
        entityControlPersonDOB: "",
        entityControlPersonDOBError: "",
        entityCertificateOfFormation: "",
        entityCertificateOfFormationError: "",
        entityProofOfAddress: "",
        entityProofOfAddressError: "",
        entityControlPersonPassportDocument: "",
        entityControlPersonPassportDocumentError: "",
        entityControlPersonProofOfAddress: "",
        entityControlPersonProofOfAddressError: "",
        entityIsTrustManagementCompany: "",
        entityIsTrustManagementCompanyError: "",
        entityHasAlternateSignatory: "",
        entityHasAlternateSignatoryError: "",
        entityAlternateSignatoryFullName: "",
        entityAlternateSignatoryFullNameError: "",
        entityAlternateSignatoryEmail: "",
        entityAlternateSignatoryEmailError: "",
        entityAlternateSignatoryTitle: "",
        entityAlternateSignatoryTitleError: "",
        entityControlPersonRole: "",
        entityControlPersonRoleError: "",
        entityNestedControlEntityTrusteeType: "",
        entityNestedControlEntityTrusteeTypeError: "",
        entityNestedControlEntityName: "",
        entityNestedControlEntityNameError: "",
        entityNestedControlEntityType: "",
        entityNestedControlEntityTypeError: "",
        entityNestedControlEntityPercentOwned: "",
        entityNestedControlEntityPercentOwnedError: "",
        entityNestedControlEntityStateOfIncorporation: "",
        entityNestedControlEntityStateOfIncorporationError: "",
        entityNestedControlEntityTaxIDNumber: "",
        entityNestedControlEntityTaxIDNumberError: "",
        entityNestedControlEntityPhoneNumber: "",
        entityNestedControlEntityPhoneNumberError: "",
        entityNestedControlEntityAddressLine1: "",
        entityNestedControlEntityAddressLine1Error: "",
        entityNestedControlEntityAddressLine2: "",
        entityNestedControlEntityAddressLine2Error: "",
        entityNestedControlEntityCity: "",
        entityNestedControlEntityCityError: "",
        entityNestedControlEntityState: "",
        entityNestedControlEntityStateError: "",
        entityNestedControlEntityPostalCode: "",
        entityNestedControlEntityPostalCodeError: "",
        entityNestedControlEntityIsTrustManagementCompany: "",
        entityNestedControlEntityIsTrustManagementCompanyError: "",
        entityNestedControlEntityAddressType: "",
        entityNestedControlEntityAddressTypeError: "",
        entityNestedControlEntityCertificateOfFormation: "",
        entityNestedControlEntityCertificateOfFormationError: "",
        entityNestedControlEntityProofOfAddress: "",
        entityNestedControlEntityProofOfAddressError: "",
        entityNestedControlEntityControlPersonFirstName: "",
        entityNestedControlEntityControlPersonFirstNameError: "",
        entityNestedControlEntityControlPersonLastName: "",
        entityNestedControlEntityControlPersonLastNameError: "",
        entityNestedControlEntityControlPersonEmail: "",
        entityNestedControlEntityControlPersonEmailError: "",
        entityNestedControlEntityControlPersonIsUSBased: "",
        entityNestedControlEntityControlPersonIsUSBasedError: "",
        entityNestedControlEntityControlPersonIsUSCitizen: "",
        entityNestedControlEntityControlPersonIsUSCitizenError: "",
        entityNestedControlEntityControlPersonPhoneNumber: "",
        entityNestedControlEntityControlPersonPhoneNumberError: "",
        entityNestedControlEntityControlPersonPIIAvailable: "",
        entityNestedControlEntityControlPersonPIIAvailableError: "",
        entityNestedControlEntityControlPersonAddressLine1: "",
        entityNestedControlEntityControlPersonAddressLine1Error: "",
        entityNestedControlEntityControlPersonAddressLine2: "",
        entityNestedControlEntityControlPersonAddressLine2Error: "",
        entityNestedControlEntityControlPersonCity: "",
        entityNestedControlEntityControlPersonCityError: "",
        entityNestedControlEntityControlPersonState: "",
        entityNestedControlEntityControlPersonStateError: "",
        entityNestedControlEntityControlPersonPostalCode: "",
        entityNestedControlEntityControlPersonPostalCodeError: "",
        entityNestedControlEntityControlPersonTaxIDNumber: "",
        entityNestedControlEntityControlPersonTaxIDNumberError: "",
        entityNestedControlEntityControlPersonDOB: "",
        entityNestedControlEntityControlPersonDOBError: "",
        entityNestedControlEntityControlPersonPassportDocument: "",
        entityNestedControlEntityControlPersonPassportDocumentError: "",
        entityNestedControlEntityControlPersonProofOfAddress: "",
        entityNestedControlEntityControlPersonProofOfAddressError: "",
        entityNestedControlEntityControlPersonAddressType: "",
        entityNestedControlEntityControlPersonAddressTypeError: "",
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        addressType: "",
        deliverableAddressLine1: "",
        deliverableAddressLine2: "",
        deliverableCity: "",
        deliverableState: "",
        deliverableCountry: "",
        deliverablePostalCode: "",
        deliverableAddressType: "",
        dateOfBirth: "",
        dateOfBirthError: "",
        idType: "",
        idNum: "",
        idNumError: "",
        domicileCountry: "",
        firstNameError: "",
        lastNameError: "",
        emailError: "",
        phoneNumberError: "",
        addressLine1Error: "",
        addressLine2Error: "",
        cityError: "",
        stateError: "",
        countryError: "",
        postalCodeError: "",
        deliverableAddressLine1Error: "",
        deliverableAddressLine2Error: "",
        deliverableCityError: "",
        deliverableStateError: "",
        deliverableCountryError: "",
        deliverablePostalCodeError: "",
        primaryAddressDeliverable: "",
        domicileCountryError: "",
        deliverableSelectionError: "",
        percentOwned: "",
        percentOwnedError: "",
        citizenship: "",
        citizenshipError: "",
      },
    ]);

  const [submissionInProgress, setSubmissionInProgress] = useState(false);

  const [hideButton, setHideButton] = useState(false);
  const [hideAlterButtons, setHideAlterButtons] = useState(false);

  const [showTrusteeSection, setShowTrusteeSection] = useState(false);
  const shouldRenderTrusteeSection = useDelayUnmount(
    showTrusteeSection,
    400,
    false
  );

  const [activeTab, setActiveTab] = useState("Trustee #1");
  const [hasTrustees, setHasTrustees] = useState("");

  const [hasBeneficialOwners, setHasBeneficialOwners] = useState("");
  const [hasBeneficialOwnersError, setHasBeneficialOwnersError] = useState("");
  const [showBeneficialOwnerSection, setShowBeneficialOwnerSection] =
    useState(false);
  const shouldRenderBeneficialOwnerSection = useDelayUnmount(
    showBeneficialOwnerSection,
    400,
    false
  );
  const [activeBeneficialOwnerTab, setActiveBeneficialOwnerTab] = useState(
    "Beneficial Owner #1"
  );
  const [beneficialOwnerHideButton, setBeneficialOwnerHideButton] =
    useState(false);

  // To-do: track idx instead of tab name?

  const submit = async () => {
    let values = [...relatedAccounts];
    let beneficialOwnerRequestData = [];
    let requestData = [];
    let trusteeRequestData = [];
    var errors = false;
    setSubmissionInProgress(true);

    if (accountType === "TRUST") {
      if (hasAlternateSignatory === "") {
        setHasAlternateSignatoryError("Please make a selection.");
        errors = true;
      }

      if (hasAlternateSignatory === true) {
        if (alternateSignatoryFullName === "") {
          setAlternateSignatoryFullNameError("This field is required.");
          errors = true;
        }
        if (alternateSignatoryEmail === "") {
          setAlternateSignatoryEmailError("This field is required.");
          errors = true;
        }
        if (alternateSignatoryTitle === "") {
          setAlternateSignatoryTitleError("This field is required.");
          errors = true;
        }
      }
    }

    if (hasTrustees === "") {
      setHasTrusteesError("Please make a selection.");
      errors = true;
    }

    if (hasBeneficialOwners === "") {
      setHasBeneficialOwnersError("Please make a selection.");
      errors = true;
    }

    if (hasTrustees === true) {
      trusteeRequestData = relatedAccounts.map((inputField, index) => {
        if (inputField.relatedPartyType === "") {
          values[index]["relatedPartyTypeError"] = "This field is required.";
          errors = true;
        }

        if (inputField.relatedPartyType === "Person") {
          if (inputField.firstName === "") {
            values[index]["firstNameError"] = "This field is required.";
            errors = true;
          }
          if (inputField.lastName === "") {
            values[index]["lastNameError"] = "This field is required.";
            errors = true;
          }
          if (inputField.email === "") {
            values[index]["emailError"] = "This field is required.";
            errors = true;
          } else if (!isEmailValid(inputField.email)) {
            values[index]["emailError"] = "Please enter a valid email.";
            errors = true;
          }
          // if (inputField.citizenship !== "United States") {
          //   values[index]["citizenshipError"] =
          //     "Sorry, we only support US citizens at this time.";
          //   errors = true;
          // }

          if (inputField.citizenship === "") {
            values[index]["citizenshipError"] =
              "Please enter your country of citizenship";
            errors = true;
          }
          //Add email validation
          if (inputField.phoneNumber === "") {
            values[index]["phoneNumberError"] =
              "Please enter a valid phone number.";
          } else if (inputField.phoneNumber.length < 7) {
            values[index][inputField.phoneNumberError] =
              "Please enter a valid phone number";
            errors = true;
          }
          if (inputField.addressLine1 === "") {
            values[index]["addressLine1Error"] = "This field is required.";
            errors = true;
          }
          if (inputField.city === "") {
            values[index]["cityError"] = "This field is required.";
            errors = true;
          }
          if (inputField.country === "United States") {
            if (inputField.state === "") {
              values[index]["stateError"] =
                "Please enter a state from the list.";
              errors = true;
            }
          }
          if (inputField.percentOwned === "") {
            values[index]["percentOwnedError"] = "This field is required";
            errors = true;
          }

          if (inputField.country === "") {
            values[index]["countryError"] =
              "Please enter a country from the list.";
            errors = true;
          } else if (!isValidCountry(inputField.country)) {
            values[index]["countryError"] =
              "Please enter a country from the list.";
            errors = true;
          }
          if (inputField.postalCode === "") {
            values[index]["postalCodeError"] = "This field is required.";
            errors = true;
          }

          if (inputField.domicileCountry === "") {
            values[index]["domicileCountryError"] =
              "Please enter a country from the list.";
            errors = true;
          } else if (!isValidCountry(inputField.domicileCountry)) {
            values[index]["domicileCountryError"] =
              "Please enter a country from the list.";
            errors = true;
          }

          if (inputField.dateOfBirth === "") {
            values[index]["dateOfBirthError"] = "This field is required.";
            errors = true;
          } else {
            const date = inputField.dateOfBirth;
            const notValid = !isDobValid(date);
            if (notValid) {
              values[index]["dateOfBirthError"] =
                "Please enter a valid date of birth.";
              errors = true;
            }
          }
          if (inputField.idNum === "") {
            values[index]["idNumError"] = "This field is required.";
            errors = true;
          } else if (
            inputField.domicileCountry === "United States" &&
            !isValidSSN(inputField.idNum)
          ) {
            errors = true;
            values[index]["idNumError"] = "Please enter a valid ssn.";
          }

          return {
            rp_type: "Person",
            first_name: inputField.firstName,
            last_name: inputField.lastName,
            email: inputField.email,
            phone_number: inputField.phoneNumber,
            address_line_1: inputField.addressLine1,
            address_line_2: inputField.addressLine2,
            city: inputField.city,
            state:
              inputField.country === "United States"
                ? inputField.state.length !== 0
                  ? inputField.state
                  : ""
                : "",
            country: inputField.country,
            postal_code: inputField.postalCode,
            address_type: "RM",
            date_of_birth: inputField.dateOfBirth,
            id_type:
              inputField.domicileCountry === "United States" ? "SSN" : "PASS",
            id_number: inputField.idNum,
            domicile_country: inputField.domicileCountry,
            role: "TST",
            address2_line_1: "",
            address2_line_2: "",
            address2_city: "",
            address2_state: "",
            address2_country: "",
            address2_postal_code: "",
            address2_type: "",
            percent_ownership: inputField.percentOwned,
            citizenship: inputField.citizenship,
            ...(supplyPercentOwned && { account_id: inputField.id }),
          };
        } else if (inputField.relatedPartyType === "Entity") {
          if (inputField.entityType === "") {
            errors = true;
            values[index]["entityTypeError"] = "This field is required.";
          }

          if (inputField.entityName === "") {
            errors = true;
            values[index]["entityNameError"] = "This field is required.";
          }

          if (inputField.entityPercentOwned === "") {
            errors = true;
            values[index]["entityPercentOwnedError"] =
              "This field is required.";
          }

          if (inputField.entityStateOfIncorporation === "") {
            errors = true;
            values[index]["entityStateOfIncorporationError"] =
              "This field is required.";
          }

          if (
            inputField.entityType !== "LT" &&
            inputField.entityType !== "SM_LLC" &&
            inputField.entityTaxIDNumber === ""
          ) {
            errors = true;
            values[index]["entityTaxIDNumberError"] = "This field is required.";
          }

          if (inputField.entityPhoneNumber === "") {
            errors = true;
            values[index]["entityPhoneNumberError"] = "This field is required.";
          }

          if (inputField.entityAddressLine1 === "") {
            errors = true;
            values[index]["entityAddressLine1Error"] =
              "This field is required.";
          }

          if (inputField.entityCity === "") {
            errors = true;
            values[index]["entityCityError"] = "This field is required.";
          }

          if (inputField.entityState === "") {
            errors = true;
            values[index]["entityStateError"] = "This field is required.";
          }

          if (inputField.entityPostalCode === "") {
            errors = true;
            values[index]["entityPostalCodeError"] = "This field is required.";
          }

          if (inputField.entityMailingAddressDifferent === "") {
            errors = true;
            values[index]["entityMailingAddressDifferentError"] =
              "This field is required.";
          }

          if (inputField.entityMailingAddressDifferent === "Yes") {
            if (inputField.entityMailingAddressLine1 === "") {
              errors = true;
              values[index]["entityMailingAddressLine1Error"] =
                "This field is required.";
            }

            if (inputField.entityMailingCity === "") {
              errors = true;
              values[index]["entityMailingCityError"] =
                "This field is required.";
            }

            if (inputField.entityMailingState === "") {
              errors = true;
              values[index]["entityMailingStateError"] =
                "This field is required.";
            }

            if (inputField.entityMailingPostalCode === "") {
              errors = true;
              values[index]["entityMailingPostalCodeError"] =
                "This field is required.";
            }
          }

          if (inputField.entityIsTrustManagementCompany === "") {
            errors = true;
            values[index]["entityIsTrustManagementCompanyError"] =
              "This field is required.";
          }

          if (inputField.entityControlPersonFirstName === "") {
            errors = true;
            values[index]["entityControlPersonFirstNameError"] =
              "This field is required.";
          }

          if (inputField.entityControlPersonLastName === "") {
            errors = true;
            values[index]["entityControlPersonLastNameError"] =
              "This field is required.";
          }

          if (inputField.entityControlPersonEmail === "") {
            errors = true;
            values[index]["entityControlPersonEmailError"] =
              "This field is required.";
          } else if (!isEmailValid(inputField.entityControlPersonEmail)) {
            errors = true;
            values[index]["entityControlPersonEmailError"] =
              "Please enter a valid email.";
          }

          if (inputField.entityControlPersonPhoneNumber === "") {
            errors = true;
            values[index]["entityControlPersonPhoneNumberError"] =
              "This field is required.";
          }

          if (inputField.entityControlPersonIsUSBased === "") {
            errors = true;
            values[index]["entityControlPersonIsUSBasedError"] =
              "This field is required.";
          } else if (inputField.entityControlPersonIsUSBased === "No") {
            errors = true;
            values[index]["entityControlPersonIsUSBasedError"] =
              "We only support US-based individuals at this time.";
          }

          if (inputField.entityControlPersonIsUSCitizen === "") {
            errors = true;
            values[index]["entityControlPersonIsUSCitizenError"] =
              "This field is required.";
          } /*else if (inputField.entityControlPersonIsUSCitizen === "No") {
            errors = true;
            values[index]["entityControlPersonIsUSCitizenError"] =
              "We only support US citizens at this time.";
          }*/

          if (inputField.entityControlPersonPIIAvailable === "") {
            errors = true;
            values[index]["entityControlPersonPIIAvailableError"] =
              "This field is required.";
          }

          if (inputField.entityControlPersonPIIAvailable === "Yes") {
            if (inputField.entityControlPersonAddressLine1 === "") {
              errors = true;
              values[index]["entityControlPersonAddressLine1Error"] =
                "This field is required.";
            }

            if (inputField.entityControlPersonCity === "") {
              errors = true;
              values[index]["entityControlPersonCityError"] =
                "This field is required.";
            }

            if (inputField.entityControlPersonState === "") {
              errors = true;
              values[index]["entityControlPersonStateError"] =
                "This field is required.";
            }

            if (inputField.entityControlPersonPostalCode === "") {
              errors = true;
              values[index]["entityControlPersonPostalCodeError"] =
                "This field is required.";
            }

            if (inputField.entityControlPersonDOB === "") {
              errors = true;
              values[index]["entityControlPersonDOBError"] =
                "This field is required.";
            } else if (!isDobValid(inputField.entityControlPersonDOB)) {
              errors = true;
              values[index]["entityControlPersonDOBError"] =
                "Please enter a valid dob.";
            }

            if (inputField.entityControlPersonTaxIDNumber === "") {
              errors = true;
              values[index]["entityControlPersonTaxIDNumberError"] =
                "This field is required.";
            }

            if (inputField.entityControlPersonPassportDocument === "") {
              errors = true;
              values[index]["entityControlPersonPassportDocumentError"] =
                "This field is required.";
            }

            if (inputField.entityControlPersonProofOfAddress === "") {
              errors = true;
              values[index]["entityControlPersonProofOfAddressError"] =
                "This field is required.";
            }
          }

          if (inputField.entityCertificateOfFormation === "") {
            errors = true;
            values[index]["entityCertificateOfFormationError"] =
              "This field is required.";
          }

          if (inputField.entityProofOfAddress === "") {
            errors = true;
            values[index]["entityProofOfAddressError"] =
              "This field is required.";
          }

          return {
            rp_type: "Entity",
            role: "TST",
            entity_type: inputField.entityType,
            entity_name: inputField.entityName,
            entity_percent_owned: inputField.entityPercentOwned,
            entity_state_of_incorporation:
              inputField.entityStateOfIncorporation,
            ...(inputField.entityType !== "LT" &&
              inputField.entityType !== "SM_LLC" && {
                entity_tax_id_number: inputField.entityTaxIDNumber,
              }),
            entity_phone_number: stripOutDigits(inputField.entityPhoneNumber),
            entity_address_line_1: inputField.entityAddressLine1,
            entity_address_line_2: inputField.entityAddressLine2,
            entity_city: inputField.entityCity,
            entity_state: inputField.entityState,
            entity_postal_code: inputField.entityPostalCode,
            address_type:
              inputField.entityMailingAddressDifferent === "Yes" ? "R" : "RM",
            ...(inputField.entityMailingAddressDifferent === "Yes" && {
              entity_mailing_address_line_1:
                inputField.entityMailingAddressLine1,
              entity_mailing_address_line_2:
                inputField.entityMailingAddressLine2,
              entity_mailing_city: inputField.entityMailingCity,
              entity_mailing_state: inputField.entityMailingState,
              entity_mailing_postal_code: inputField.entityMailingPostalCode,
            }),
            entity_is_trust_management_company:
              inputField.entityIsTrustManagementCompany === "Yes",
            entity_control_person_first_name:
              inputField.entityControlPersonFirstName,
            entity_control_person_last_name:
              inputField.entityControlPersonLastName,
            entity_control_person_email: inputField.entityControlPersonEmail,
            entity_control_person_phone_number: stripOutDigits(
              inputField.entityControlPersonPhoneNumber
            ),
            entity_control_person_is_us_based:
              inputField.entityControlPersonIsUSBased === "Yes",
            entity_control_person_is_us_citizen:
              inputField.entityControlPersonIsUSCitizen === "Yes",
            ...(inputField.entityControlPersonPIIAvailable === "Yes"
              ? {
                  entity_control_person_address_line_1:
                    inputField.entityControlPersonAddressLine1,
                  entity_control_person_address_line_2:
                    inputField.entityControlPersonAddressLine2,
                  entity_control_person_city:
                    inputField.entityControlPersonCity,
                  entity_control_person_state:
                    inputField.entityControlPersonState,
                  entity_control_person_postal_code:
                    inputField.entityControlPersonPostalCode,
                  entity_control_person_dob: inputField.entityControlPersonDOB,
                  entity_control_person_tax_id_number:
                    inputField.entityControlPersonTaxIDNumber,
                }
              : {}),
            documents: [
              {
                category: "Entity Proof of Address",
                b64_string: inputField.entityProofOfAddress.b64_string,
                name: "Entity Proof of Address",
              },
              {
                category: "Entity Certificate of Formation",
                b64_string: inputField.entityCertificateOfFormation.b64_string,
                name: "Entity Certificate of Formation",
              },
              ...(inputField.entityControlPersonPIIAvailable === "Yes"
                ? [
                    {
                      category: "Entity Control Person Passport Document",
                      b64_string:
                        inputField.entityControlPersonPassportDocument
                          .b64_string,
                      name: "Entity Control Person Passport Document",
                    },
                    {
                      category: "Entity Control Person Proof of Address",
                      b64_string:
                        inputField.entityControlPersonProofOfAddress.b64_string,
                      name: "Entity Control Person Proof of Address",
                    },
                  ]
                : []),
            ],
          };
        }
      });

      setRelatedAccounts(values);
    }

    if (hasBeneficialOwners === true) {
      let beneficialOwnerValues = [...beneficialOwnerRelatedAccounts];
      beneficialOwnerRequestData = beneficialOwnerRelatedAccounts.map(
        (inputField, index) => {
          if (inputField.relatedPartyType === "Entity") {
            if (inputField.entityType === "") {
              errors = true;
              beneficialOwnerValues[index]["entityTypeError"] =
                "This field is required.";
            }

            if (inputField.entityName === "") {
              errors = true;
              beneficialOwnerValues[index]["entityNameError"] =
                "This field is required.";
            }

            if (inputField.entityPercentOwned === "") {
              errors = true;
              beneficialOwnerValues[index]["entityPercentOwnedError"] =
                "This field is required.";
            } else if (parseInt(inputField.entityPercentOwned) === 0) {
              errors = true;
              beneficialOwnerValues[index]["entityPercentOwnedError"] =
                "Percent owned must be a value greater than 0.";
            }

            if (inputField.entityStateOfIncorporation === "") {
              errors = true;
              beneficialOwnerValues[index]["entityStateOfIncorporationError"] =
                "This field is required.";
            }

            if (
              inputField.entityType !== "LT" &&
              inputField.entityType !== "SM_LLC" &&
              inputField.entityTaxIDNumber === ""
            ) {
              errors = true;
              beneficialOwnerValues[index]["entityTaxIDNumberError"] =
                "This field is required.";
            }

            if (inputField.entityPhoneNumber === "") {
              errors = true;
              beneficialOwnerValues[index]["entityPhoneNumberError"] =
                "This field is required.";
            }

            if (inputField.entityAddressLine1 === "") {
              errors = true;
              beneficialOwnerValues[index]["entityAddressLine1Error"] =
                "This field is required.";
            }

            if (inputField.entityCity === "") {
              errors = true;
              beneficialOwnerValues[index]["entityCityError"] =
                "This field is required.";
            }

            if (inputField.entityState === "") {
              errors = true;
              beneficialOwnerValues[index]["entityStateError"] =
                "This field is required.";
            }

            if (inputField.entityPostalCode === "") {
              errors = true;
              beneficialOwnerValues[index]["entityPostalCodeError"] =
                "This field is required.";
            }

            if (inputField.entityMailingAddressDifferent === "") {
              errors = true;
              beneficialOwnerValues[index][
                "entityMailingAddressDifferentError"
              ] = "This field is required.";
            }

            if (inputField.entityMailingAddressDifferent === "Yes") {
              if (inputField.entityMailingAddressLine1 === "") {
                errors = true;
                beneficialOwnerValues[index]["entityMailingAddressLine1Error"] =
                  "This field is required.";
              }

              if (inputField.entityMailingCity === "") {
                errors = true;
                beneficialOwnerValues[index]["entityMailingCityError"] =
                  "This field is required.";
              }

              if (inputField.entityMailingState === "") {
                errors = true;
                beneficialOwnerValues[index]["entityMailingStateError"] =
                  "This field is required.";
              }

              if (inputField.entityMailingPostalCode === "") {
                errors = true;
                beneficialOwnerValues[index]["entityMailingPostalCodeError"] =
                  "This field is required.";
              }
            }

            // if (inputField.entityIsTrustManagementCompany === "") {
            //   errors = true;
            //   beneficialOwnerValues[index]["entityIsTrustManagementCompanyError"] =
            //     "This field is required.";
            // }

            if (inputField.entityType === "TRUST") {
              if (inputField.entityNestedControlEntityTrusteeType === "") {
                errors = true;
                beneficialOwnerValues[index][
                  "entityNestedControlEntityTrusteeTypeError"
                ] = "This field is required.";
              } else if (
                inputField.entityNestedControlEntityTrusteeType === "Person"
              ) {
                if (inputField.entityControlPersonFirstName === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonFirstNameError"
                  ] = "This field is required.";
                }

                if (inputField.entityControlPersonLastName === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonLastNameError"
                  ] = "This field is required.";
                }

                if (inputField.entityControlPersonEmail === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonEmailError"
                  ] = "This field is required.";
                }

                if (inputField.entityControlPersonPhoneNumber === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonPhoneNumberError"
                  ] = "This field is required.";
                }

                if (inputField.entityControlPersonIsUSBased === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonIsUSBasedError"
                  ] = "This field is required.";
                } else if (inputField.entityControlPersonIsUSBased === "No") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonIsUSBasedError"
                  ] = "We only support US-based individuals at this time.";
                }

                if (inputField.entityControlPersonIsUSCitizen === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonIsUSCitizenError"
                  ] = "This field is required.";
                } /*else if (inputField.entityControlPersonIsUSCitizen === "No") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonIsUSCitizenError"
                  ] = "We only support US citizens at this time.";
                }*/

                if (inputField.entityControlPersonAddressLine1 === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonAddressLine1Error"
                  ] = "This field is required.";
                }

                if (inputField.entityControlPersonCity === "") {
                  errors = true;
                  beneficialOwnerValues[index]["entityControlPersonCityError"] =
                    "This field is required.";
                }

                if (inputField.entityControlPersonState === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonStateError"
                  ] = "This field is required.";
                }

                if (inputField.entityControlPersonPostalCode === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonPostalCodeError"
                  ] = "This field is required.";
                }

                if (inputField.entityControlPersonDOB === "") {
                  errors = true;
                  beneficialOwnerValues[index]["entityControlPersonDOBError"] =
                    "This field is required.";
                } else if (!isDobValid(inputField.entityControlPersonDOB)) {
                  errors = true;
                  beneficialOwnerValues[index]["entityControlPersonDOBError"] =
                    "Please enter a valid dob.";
                }

                if (inputField.entityControlPersonTaxIDNumber === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonTaxIDNumberError"
                  ] = "This field is required.";
                } else if (
                  !isValidSSN(inputField.entityControlPersonTaxIDNumber)
                ) {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonTaxIDNumberError"
                  ] = "Please enter a valid ssn.";
                }

                if (inputField.entityControlPersonPassportDocument === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonPassportDocumentError"
                  ] = "This field is required.";
                }

                if (inputField.entityControlPersonProofOfAddress === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonProofOfAddressError"
                  ] = "This field is required.";
                }

                if (inputField.entityCertificateOfFormation === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityCertificateOfFormationError"
                  ] = "This field is required.";
                }

                if (inputField.entityProofOfAddress === "") {
                  errors = true;
                  beneficialOwnerValues[index]["entityProofOfAddressError"] =
                    "This field is required.";
                }
              } else {
                if (inputField.entityNestedControlEntityType === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityTypeError"
                  ] = "This field is required.";
                }

                if (inputField.entityNestedControlEntityName === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityNameError"
                  ] = "This field is required.";
                }

                if (inputField.entityNestedControlEntityPercentOwned === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityPercentOwnedError"
                  ] = "This field is required.";
                }

                if (
                  inputField.entityNestedControlEntityStateOfIncorporation ===
                  ""
                ) {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityStateOfIncorporationError"
                  ] = "This field is required.";
                }

                if (inputField.entityNestedControlEntityPhoneNumber === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityPhoneNumberError"
                  ] = "This field is required.";
                }

                if (
                  inputField.entityNestedControlEntityTaxIDNumber === "" &&
                  inputField.entityNestedControlEntityType !== "SM_LLC"
                ) {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityTaxIDNumberError"
                  ] = "This field is required.";
                }

                if (inputField.entityNestedControlEntityAddressLine1 === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityAddressLine1Error"
                  ] = "This field is required.";
                }

                if (inputField.entityNestedControlEntityCity === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityCityError"
                  ] = "This field is required.";
                }

                if (inputField.entityNestedControlEntityState === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityStateError"
                  ] = "This field is required.";
                }

                if (inputField.entityNestedControlEntityPostalCode === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityPostalCodeError"
                  ] = "This field is required.";
                }

                if (
                  inputField.entityNestedControlEntityIsTrustManagementCompany ===
                  ""
                ) {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityIsTrustManagementCompanyError"
                  ] = "This field is required.";
                }

                if (
                  inputField.entityNestedControlEntityControlPersonFirstName ===
                  ""
                ) {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityControlPersonFirstNameError"
                  ] = "This field is required.";
                }

                if (
                  inputField.entityNestedControlEntityControlPersonLastName ===
                  ""
                ) {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityControlPersonLastNameError"
                  ] = "This field is required.";
                }

                if (
                  inputField.entityNestedControlEntityControlPersonEmail === ""
                ) {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityControlPersonEmailError"
                  ] = "This field is required.";
                }

                if (
                  inputField.entityNestedControlEntityControlPersonPhoneNumber ===
                  ""
                ) {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityControlPersonPhoneNumberError"
                  ] = "This field is required.";
                }

                if (
                  inputField.entityNestedControlEntityControlPersonIsUSBased ===
                  ""
                ) {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityControlPersonIsUSBasedError"
                  ] = "This field is required.";
                } else if (
                  inputField.entityNestedControlEntityControlPersonIsUSBased ===
                  "No"
                ) {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityControlPersonIsUSBasedError"
                  ] = "We only support US-based individuals at this time.";
                }

                if (
                  inputField.entityNestedControlEntityControlPersonIsUSCitizen ===
                  ""
                ) {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityControlPersonIsUSCitizenError"
                  ] = "This field is required.";
                } /*else if (
                  inputField.entityNestedControlEntityControlPersonIsUSCitizen ===
                  "No"
                ) {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityControlPersonIsUSCitizenError"
                  ] = "We only support US citizens at this time.";
                }*/

                if (
                  inputField.entityNestedControlEntityControlPersonPIIAvailable ===
                  ""
                ) {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityControlPersonPIIAvailableError"
                  ] = "This field is required.";
                } else if (
                  inputField.entityNestedControlEntityControlPersonPIIAvailable ===
                  "Yes"
                ) {
                  if (
                    inputField.entityNestedControlEntityControlPersonTaxIDNumber ===
                    ""
                  ) {
                    errors = true;
                    beneficialOwnerValues[index][
                      "entityNestedControlEntityControlPersonTaxIDNumberError"
                    ] = "This field is required.";
                  } else if (
                    !isValidSSN(
                      inputField.entityNestedControlEntityControlPersonTaxIDNumber
                    )
                  ) {
                    errors = true;
                    beneficialOwnerValues[index][
                      "entityNestedControlEntityControlPersonTaxIDNumberError"
                    ] = "Please enter a valid ssn.";
                  }

                  if (
                    inputField.entityNestedControlEntityControlPersonDOB === ""
                  ) {
                    errors = true;
                    beneficialOwnerValues[index][
                      "entityNestedControlEntityControlPersonDOBError"
                    ] = "This field is required.";
                  } else if (
                    !isDobValid(
                      inputField.entityNestedControlEntityControlPersonDOB
                    )
                  ) {
                    errors = true;
                    beneficialOwnerValues[index][
                      "entityNestedControlEntityControlPersonDOBError"
                    ] = "Please enter a valid dob.";
                  }

                  if (
                    inputField.entityNestedControlEntityControlPersonAddressLine1 ===
                    ""
                  ) {
                    errors = true;
                    beneficialOwnerValues[index][
                      "entityNestedControlEntityControlPersonAddressLine1Error"
                    ] = "This field is required.";
                  }

                  if (
                    inputField.entityNestedControlEntityControlPersonCity === ""
                  ) {
                    errors = true;
                    beneficialOwnerValues[index][
                      "entityNestedControlEntityControlPersonCityError"
                    ] = "This field is required.";
                  }

                  if (
                    inputField.entityNestedControlEntityControlPersonState ===
                    ""
                  ) {
                    errors = true;
                    beneficialOwnerValues[index][
                      "entityNestedControlEntityControlPersonStateError"
                    ] = "This field is required.";
                  }

                  if (
                    inputField.entityNestedControlEntityControlPersonPostalCode ===
                    ""
                  ) {
                    errors = true;
                    beneficialOwnerValues[index][
                      "entityNestedControlEntityControlPersonPostalCodeError"
                    ] = "This field is required.";
                  }

                  if (
                    inputField.entityNestedControlEntityControlPersonPassportDocument ===
                    ""
                  ) {
                    errors = true;
                    beneficialOwnerValues[index][
                      "entityNestedControlEntityControlPersonPassportDocumentError"
                    ] = "This field is required.";
                  }

                  if (
                    inputField.entityNestedControlEntityControlPersonProofOfAddress ===
                    ""
                  ) {
                    errors = true;
                    beneficialOwnerValues[index][
                      "entityNestedControlEntityControlPersonProofOfAddressError"
                    ] = "This field is required.";
                  }
                }

                if (inputField.entityCertificateOfFormation === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityCertificateOfFormationError"
                  ] = "This field is required.";
                }

                if (inputField.entityProofOfAddress === "") {
                  errors = true;
                  beneficialOwnerValues[index]["entityProofOfAddressError"] =
                    "This field is required.";
                }

                if (
                  inputField.entityNestedControlEntityCertificateOfFormation ===
                  ""
                ) {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityCertificateOfFormationError"
                  ] = "This field is required.";
                }

                if (inputField.entityNestedControlEntityProofOfAddress === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityNestedControlEntityProofOfAddressError"
                  ] = "This field is required.";
                }
              }
            } else {
              if (inputField.entityControlPersonFirstName === "") {
                errors = true;
                beneficialOwnerValues[index][
                  "entityControlPersonFirstNameError"
                ] = "This field is required.";
              }

              if (inputField.entityControlPersonLastName === "") {
                errors = true;
                beneficialOwnerValues[index][
                  "entityControlPersonLastNameError"
                ] = "This field is required.";
              }

              if (inputField.entityControlPersonEmail === "") {
                errors = true;
                beneficialOwnerValues[index]["entityControlPersonEmailError"] =
                  "This field is required.";
              } else if (!isEmailValid(inputField.entityControlPersonEmail)) {
                errors = true;
                beneficialOwnerValues[index]["entityControlPersonEmailError"] =
                  "Please enter a valid email.";
              }

              if (inputField.entityControlPersonPhoneNumber === "") {
                errors = true;
                beneficialOwnerValues[index][
                  "entityControlPersonPhoneNumberError"
                ] = "This field is required.";
              }

              if (inputField.entityControlPersonIsUSBased === "") {
                errors = true;
                beneficialOwnerValues[index][
                  "entityControlPersonIsUSBasedError"
                ] = "This field is required.";
              } else if (inputField.entityControlPersonIsUSBased === "No") {
                errors = true;
                beneficialOwnerValues[index][
                  "entityControlPersonIsUSBasedError"
                ] = "We only support US-based individuals at this time.";
              }

              if (inputField.entityControlPersonIsUSCitizen === "") {
                errors = true;
                beneficialOwnerValues[index][
                  "entityControlPersonIsUSCitizenError"
                ] = "This field is required.";
              } /*else if (inputField.entityControlPersonIsUSCitizen === "No") {
                errors = true;
                beneficialOwnerValues[index][
                  "entityControlPersonIsUSCitizenError"
                ] = "We only support US citizens at this time.";
              }*/

              if (inputField.entityControlPersonPIIAvailable === "") {
                errors = true;
                beneficialOwnerValues[index][
                  "entityControlPersonPIIAvailableError"
                ] = "This field is required.";
              }

              if (inputField.entityControlPersonPIIAvailable === "Yes") {
                if (inputField.entityControlPersonAddressLine1 === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonAddressLine1Error"
                  ] = "This field is required.";
                }

                if (inputField.entityControlPersonCity === "") {
                  errors = true;
                  beneficialOwnerValues[index]["entityControlPersonCityError"] =
                    "This field is required.";
                }

                if (inputField.entityControlPersonState === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonStateError"
                  ] = "This field is required.";
                }

                if (inputField.entityControlPersonPostalCode === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonPostalCodeError"
                  ] = "This field is required.";
                }

                if (inputField.entityControlPersonDOB === "") {
                  errors = true;
                  beneficialOwnerValues[index]["entityControlPersonDOBError"] =
                    "This field is required.";
                } else if (!isDobValid(inputField.entityControlPersonDOB)) {
                  errors = true;
                  beneficialOwnerValues[index]["entityControlPersonDOBError"] =
                    "Please enter a valid dob.";
                }

                if (inputField.entityControlPersonTaxIDNumber === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonTaxIDNumberError"
                  ] = "This field is required.";
                }

                if (inputField.entityControlPersonPassportDocument === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonPassportDocumentError"
                  ] = "This field is required.";
                }

                if (inputField.entityControlPersonProofOfAddress === "") {
                  errors = true;
                  beneficialOwnerValues[index][
                    "entityControlPersonProofOfAddressError"
                  ] = "This field is required.";
                }
              }

              if (inputField.entityCertificateOfFormation === "") {
                errors = true;
                beneficialOwnerValues[index][
                  "entityCertificateOfFormationError"
                ] = "This field is required.";
              }

              if (inputField.entityProofOfAddress === "") {
                errors = true;
                beneficialOwnerValues[index]["entityProofOfAddressError"] =
                  "This field is required.";
              }
            }

            return {
              rp_type: "Entity",
              role: "BO",
              entity_type: inputField.entityType,
              entity_name: inputField.entityName,
              entity_percent_owned: inputField.entityPercentOwned,
              entity_state_of_incorporation:
                inputField.entityStateOfIncorporation,
              ...(inputField.entityType !== "LT" &&
                inputField.entityType !== "SM_LLC" && {
                  entity_tax_id_number: inputField.entityTaxIDNumber,
                }),
              entity_phone_number: stripOutDigits(inputField.entityPhoneNumber),
              entity_address_line_1: inputField.entityAddressLine1,
              entity_address_line_2: inputField.entityAddressLine2,
              entity_city: inputField.entityCity,
              entity_state: inputField.entityState,
              entity_postal_code: inputField.entityPostalCode,
              address_type:
                inputField.entityMailingAddressDifferent === "Yes" ? "R" : "RM",
              entity_mailing_address_line_1:
                inputField.entityMailingAddressLine1,
              entity_mailing_address_line_2:
                inputField.entityMailingAddressLine2,
              entity_mailing_city: inputField.entityMailingCity,
              entity_mailing_state: inputField.entityMailingState,
              entity_mailing_postal_code: inputField.entityMailingPostalCode,
              ...(inputField.entityType !== "TRUST" && {
                entity_control_person_first_name:
                  inputField.entityControlPersonFirstName,
                entity_control_person_last_name:
                  inputField.entityControlPersonLastName,
                entity_control_person_email:
                  inputField.entityControlPersonEmail,
                entity_control_person_phone_number: stripOutDigits(
                  inputField.entityControlPersonPhoneNumber
                ),
                entity_control_person_is_us_based:
                  inputField.entityControlPersonIsUSBased,
                entity_control_person_is_us_citizen:
                  inputField.entityControlPersonIsUSCitizen,
                ...(inputField.entityControlPersonPIIAvailable === "Yes"
                  ? {
                      entity_control_person_address_line_1:
                        inputField.entityControlPersonAddressLine1,
                      entity_control_person_address_line_2:
                        inputField.entityControlPersonAddressLine2,
                      entity_control_person_city:
                        inputField.entityControlPersonCity,
                      entity_control_person_state:
                        inputField.entityControlPersonState,
                      entity_control_person_postal_code:
                        inputField.entityControlPersonPostalCode,
                      entity_control_person_dob:
                        inputField.entityControlPersonDOB,
                      entity_control_person_tax_id_number:
                        inputField.entityControlPersonTaxIDNumber,
                    }
                  : {}),
                documents: [
                  {
                    category: "Entity Proof of Address",
                    b64_string: inputField.entityProofOfAddress.b64_string,
                    name: "Entity Proof of Address",
                  },
                  {
                    category: "Entity Certificate of Formation",
                    b64_string:
                      inputField.entityCertificateOfFormation.b64_string,
                    name: "Entity Certificate of Formation",
                  },
                  ...(inputField.entityControlPersonPIIAvailable === "Yes"
                    ? [
                        {
                          category: "Entity Control Person Passport Document",
                          b64_string:
                            inputField.entityControlPersonPassportDocument
                              .b64_string,
                          name: "Entity Control Person Passport Document",
                        },
                        {
                          category: "Entity Control Person Proof of Address",
                          b64_string:
                            inputField.entityControlPersonProofOfAddress
                              .b64_string,
                          name: "Entity Control Person Proof of Address",
                        },
                      ]
                    : []),
                ],
              }),
              ...(inputField.entityType === "TRUST" && {
                ...(inputField.entityNestedControlEntityTrusteeType ===
                  "Person" && {
                  entity_control_person_first_name:
                    inputField.entityControlPersonFirstName,
                  entity_control_person_last_name:
                    inputField.entityControlPersonLastName,
                  entity_control_person_email:
                    inputField.entityControlPersonEmail,
                  entity_control_person_phone_number: stripOutDigits(
                    inputField.entityControlPersonPhoneNumber
                  ),
                  entity_control_person_is_us_based:
                    inputField.entityControlPersonIsUSBased,
                  entity_control_person_is_us_citizen:
                    inputField.entityControlPersonIsUSCitizen,
                  entity_control_person_address_line_1:
                    inputField.entityControlPersonAddressLine1,
                  entity_control_person_address_line_2:
                    inputField.entityControlPersonAddressLine2,
                  entity_control_person_city:
                    inputField.entityControlPersonCity,
                  entity_control_person_state:
                    inputField.entityControlPersonState,
                  entity_control_person_postal_code:
                    inputField.entityControlPersonPostalCode,
                  entity_control_person_dob: inputField.entityControlPersonDOB,
                  entity_control_person_tax_id_number:
                    inputField.entityControlPersonTaxIDNumber,
                  documents: [
                    {
                      category: "Entity Proof of Address",
                      b64_string: inputField.entityProofOfAddress.b64_string,
                      name: "Entity Proof of Address",
                    },
                    {
                      category: "Entity Certificate of Formation",
                      b64_string:
                        inputField.entityCertificateOfFormation.b64_string,
                      name: "Entity Certificate of Formation",
                    },

                    {
                      category: "Entity Control Person Passport Document",
                      b64_string:
                        inputField.entityControlPersonPassportDocument
                          .b64_string,
                      name: "Entity Control Person Passport Document",
                    },
                    {
                      category: "Entity Control Person Proof of Address",
                      b64_string:
                        inputField.entityControlPersonProofOfAddress.b64_string,
                      name: "Entity Control Person Proof of Address",
                    },
                  ],
                }),
                ...(inputField.entityNestedControlEntityTrusteeType ===
                  "Entity" && {
                  nested_rp_type: "Entity",
                  nested_role: "TST",
                  nested_entity_type: inputField.entityNestedControlEntityType,
                  nested_entity_name: inputField.entityNestedControlEntityName,
                  nested_entity_state_of_incorporation:
                    inputField.entityNestedControlEntityStateOfIncorporation,
                  nested_entity_percent_owned:
                    inputField.entityNestedControlEntityPercentOwned,
                  nested_entity_phone_number: stripOutDigits(
                    inputField.entityNestedControlEntityPhoneNumber
                  ),
                  nested_entity_tax_id_number:
                    inputField.entityNestedControlEntityTaxIDNumber,
                  nested_entity_address_type: "RM",
                  nested_entity_address_line_1:
                    inputField.entityNestedControlEntityAddressLine1,
                  nested_entity_address_line_2:
                    inputField.entityNestedControlEntityAddressLine2,
                  nested_entity_city: inputField.entityNestedControlEntityCity,
                  nested_entity_state:
                    inputField.entityNestedControlEntityState,
                  nested_entity_postal_code:
                    inputField.entityNestedControlEntityPostalCode,
                  nested_entity_is_trust_management_company:
                    inputField.entityNestedControlEntityIsTrustManagementCompany,
                  nested_entity_mailing_address_line_1: "",
                  nested_entity_mailing_address_line_2: "",
                  nested_entity_mailing_city: "",
                  nested_entity_mailing_state: "",
                  nested_entity_mailing_postal_code: "",
                  nested_entity_control_person_first_name:
                    inputField.entityNestedControlEntityControlPersonFirstName,
                  nested_entity_control_person_last_name:
                    inputField.entityNestedControlEntityControlPersonLastName,
                  nested_entity_control_person_email:
                    inputField.entityNestedControlEntityControlPersonEmail,
                  nested_entity_control_person_phone_number: stripOutDigits(
                    inputField.entityNestedControlEntityControlPersonPhoneNumber
                  ),
                  nested_entity_control_person_is_us_based:
                    inputField.entityNestedControlEntityControlPersonIsUSBased,
                  nested_entity_control_person_is_us_citizen:
                    inputField.entityNestedControlEntityControlPersonIsUSCitizen,
                  nested_entity_control_person_address_line_1:
                    inputField.entityNestedControlEntityControlPersonPIIAvailable ===
                    "Yes"
                      ? inputField.entityNestedControlEntityControlPersonAddressLine1
                      : "",
                  nested_entity_control_person_address_line_2:
                    inputField.entityNestedControlEntityControlPersonPIIAvailable ===
                    "Yes"
                      ? inputField.entityNestedControlEntityAddressLine2
                      : "",
                  nested_entity_control_person_city:
                    inputField.entityNestedControlEntityControlPersonPIIAvailable ===
                    "Yes"
                      ? inputField.entityNestedControlEntityControlPersonCity
                      : "",
                  nested_entity_control_person_state:
                    inputField.entityNestedControlEntityControlPersonPIIAvailable ===
                    "Yes"
                      ? inputField.entityNestedControlEntityControlPersonState
                      : "",
                  nested_entity_control_person_postal_code:
                    inputField.entityNestedControlEntityControlPersonPIIAvailable ===
                    "Yes"
                      ? inputField.entityNestedControlEntityControlPersonPostalCode
                      : "",
                  nested_entity_control_person_dob:
                    inputField.entityNestedControlEntityControlPersonPIIAvailable ===
                    "Yes"
                      ? inputField.entityNestedControlEntityControlPersonDOB
                      : "",
                  nested_entity_control_person_tax_id_number:
                    inputField.entityNestedControlEntityControlPersonPIIAvailable ===
                    "Yes"
                      ? inputField.entityNestedControlEntityControlPersonTaxIDNumber
                      : "",
                  documents: [
                    {
                      category: "Trust Proof of Address",
                      b64_string: inputField.entityProofOfAddress.b64_string,
                      name: "Trust Proof of Address",
                    },
                    {
                      category: "Trust Certificate of Formation",
                      b64_string:
                        inputField.entityCertificateOfFormation.b64_string,
                      name: "Trust Certificate of Formation",
                    },
                  ],
                  nested_documents: [
                    {
                      category: "Trustee Certificate of Formation",
                      b64_string:
                        inputField
                          .entityNestedControlEntityCertificateOfFormation
                          .b64_string,
                      name: "Trust Certificate of Formation",
                    },
                    {
                      category: "Trustee Proof of Address",
                      b64_string:
                        inputField.entityNestedControlEntityProofOfAddress
                          .b64_string,
                      name: "Trust Proof of Address",
                    },
                    ...(inputField.entityNestedControlEntityControlPersonPIIAvailable ===
                    "Yes"
                      ? [
                          {
                            category:
                              "Trustee Entity Control Person Passport Document",
                            b64_string:
                              inputField
                                .entityNestedControlEntityControlPersonPassportDocument
                                .b64_string,
                            name: "Trustee Entity Control Person Passport Document",
                          },
                          {
                            category:
                              "Trustee Entity Control Person Proof of Address",
                            b64_string:
                              inputField
                                .entityNestedControlEntityControlPersonProofOfAddress
                                .b64_string,
                            name: "Trustee Entity Control Person Proof of Address",
                          },
                        ]
                      : []),
                  ],
                }),
              }),
            };
          } else {
            if (inputField.firstName === "") {
              beneficialOwnerValues[index]["firstNameError"] =
                "This field is required.";
              errors = true;
            }

            if (inputField.lastName === "") {
              beneficialOwnerValues[index]["lastNameError"] =
                "This field is required.";
              errors = true;
            }

            if (inputField.email === "") {
              beneficialOwnerValues[index]["emailError"] =
                "This field is required.";
              errors = true;
            } else if (!isEmailValid(inputField.email)) {
              beneficialOwnerValues[index]["emailError"] =
                "Please enter a valid email.";
              errors = true;
            }

            if (inputField.phoneNumber === "") {
              beneficialOwnerValues[index]["phoneNumberError"] =
                "This field is required.";
              errors = true;
            } else if (stripOutDigits(inputField.phoneNumber).length < 11) {
              beneficialOwnerValues[index]["phoneNumberError"] =
                "Please enter a valid phone number.";
              errors = true;
            }

            if (inputField.isUSCitizen === "") {
              beneficialOwnerValues[index]["isUSCitizenError"] =
                "This field is required.";
              errors = true;
            }

            if (inputField.addressLine1 === "") {
              beneficialOwnerValues[index]["addressLine1Error"] =
                "This field is required.";
              errors = true;
            }

            if (inputField.city === "") {
              beneficialOwnerValues[index]["cityError"] =
                "This field is required.";
              errors = true;
            }

            if (
              inputField.state === "" &&
              inputField.country === "United States"
            ) {
              beneficialOwnerValues[index]["stateError"] =
                "This field is required.";
              errors = true;
            }

            if (inputField.postalCode === "") {
              beneficialOwnerValues[index]["postalCodeError"] =
                "This field is required.";
              errors = true;
            }

            if (inputField.dateOfBirth === "") {
              beneficialOwnerValues[index]["dateOfBirthError"] =
                "This field is required.";
              errors = true;
            } else if (!isDobValid(inputField.dateOfBirth)) {
              beneficialOwnerValues[index]["dateOfBirthError"] =
                "Please enter a valid date of birth.";
              errors = true;
            }

            if (inputField.idNum === "") {
              beneficialOwnerValues[index]["idNumError"] =
                "This field is requred.";
              errors = true;
            } else if (
              !isValidSSN(inputField.idNum) &&
              inputField.country === "United States"
            ) {
              beneficialOwnerValues[index]["idNumError"] =
                "Please enter a valid ssn.";
              errors = true;
            }

            if (inputField.percentOwned === "") {
              beneficialOwnerValues[index]["percentOwnedError"] =
                "This field is required.";
              errors = true;
            }

            return {
              rp_type: "Person",
              first_name: inputField.firstName,
              last_name: inputField.lastName,
              email: inputField.email,
              phone_number: stripOutDigits(inputField.phoneNumber),
              address_line_1: inputField.addressLine1,
              address_line_2: inputField.addressLine2,
              city: inputField.city,
              state: inputField.state,
              postal_code: inputField.postalCode,
              address_type: "RM",
              date_of_birth: inputField.dateOfBirth,
              id_type: inputField.country === "United States" ? "SSN" : "PASS",
              id_number: inputField.idNum,
              domicile_country: inputField.country,
              role: "BO",
              is_us_citizen: inputField.isUSCitizen,
              percent_ownership: inputField.percentOwned,
              country: inputField.country,
              ...(inputField.isUSCitizen !== "Yes"
                ? { citizenship: inputField.citizenship }
                : { citizenship: "United States" }),
            };
          }
        }
      );

      setBeneficialOwnerRelatedAccounts(beneficialOwnerValues);
    }

    if (grantorFirstName === "") {
      setGrantorFirstNameError("This field is required.");
      errors = true;
    }

    if (grantorLastName === "") {
      setGrantorLastNameError("This field is required.");
      errors = true;
    }

    if (grantorEmail === "") {
      setGrantorEmailError("This field is required.");
      errors = true;
    } else if (!isEmailValid(grantorEmail)) {
      setGrantorEmailError("Please enter a valid email.");
      errors = true;
    }

    if (grantorPhoneNumber === "") {
      setGrantorPhoneNumberError("Please enter a phone number.");
      errors = true;
    } else if (grantorPhoneNumber.length < 7) {
      setGrantorPhoneNumberError("Please enter a valid phone number");
      errors = true;
    }

    if (grantorDomicileCountry === "") {
      setGrantorDomicileCountryError(
        "Please enter a domicile country from the dropdown."
      );
      errors = true;
    }

    if (grantorAddressLine1 === "") {
      setGrantorAddressLine1Error("This field is required.");
      errors = true;
    }

    if (grantorCity === "") {
      setGrantorCityError("This field is required.");
      errors = true;
    }

    if (grantorCountry === "United States" && grantorState === "") {
      setGrantorStateError("Please enter a state from the dropdown.");
      errors = true;
    }

    if (grantorCountry === "") {
      setGrantorCountryError("Please enter a country from the dropdown.");
      errors = true;
    }

    if (grantorPostalCode === "") {
      setGrantorPostalCodeError("This field is required.");
      errors = true;
    }

    if (grantorDOB === "") {
      setGrantorDOBError("This field is required.");
      errors = true;
    } else if (!isDobValid(grantorDOB)) {
      setGrantorDOBError("Please enter a valid dob.");
      errors = true;
    }

    if (grantorIDNumber === "") {
      setGrantorIDNumberError("This field is required.");
      errors = true;
    }

    if (grantorCitizenship === "") {
      setGrantorCitizenshipError("Please enter your country of citizenship.");
      errors = true;
    }

    if (
      grantorDomicileCountryError === "United States" &&
      !isValidSSN(grantorIDNumber)
    ) {
      setGrantorIDNumberError("Please enter a valid ssn.");
      errors = true;
    }

    // if (relatedAccounts.length >= 1) {
    if (errors) {
      setSubmissionInProgress(false);
      return;
    } else {
      let grantorInformation = {
        rp_type: "Person",
        first_name: grantorFirstName,
        last_name: grantorLastName,
        email: grantorEmail,
        phone_number: grantorPhoneNumber,
        address_line_1: grantorAddressLine1,
        address_line_2: grantorAddressLine2,
        city: grantorCity,
        state:
          grantorCountry === "United States"
            ? grantorState.length !== 0
              ? grantorState
              : ""
            : "",
        country: grantorCountry,
        postal_code: grantorPostalCode,
        address_type: "RM",
        date_of_birth: grantorDOB,
        id_type: grantorDomicileCountry === "United States" ? "SSN" : "PASS",
        id_number: grantorIDNumber,
        account_id: accountID,
        domicile_country: grantorDomicileCountry,
        role: "G",
        address2_line_1: "",
        address2_line_2: "",
        address2_city: "",
        address2_state: "",
        address2_country: "",
        address2_postal_code: "",
        address2_type: "",
        citizenship: grantorCitizenship,
        percent_ownership: grantorPercentOwned,
      };
      requestData.push(grantorInformation);
      requestData = requestData.concat(trusteeRequestData);
      requestData = requestData.concat(beneficialOwnerRequestData);
      // make request to backend with grantor and trustee information here

      try {
        await updateRelatedPartyInformation({
          data: requestData,
          account_id: accountID,
          has_related_party: hasTrustees || hasBeneficialOwners,
          ...(accountType === "TRUST" &&
            hasAlternateSignatory === "Yes" && {
              signer_information: {
                name: alternateSignatoryFullName,
                title: alternateSignatoryTitle,
                email: alternateSignatoryEmail,
              },
            }),
        });

        setHideButton(true);
        setHideAlterButtons(true);
        //history.push("/investment-accounts");
        // Make sure that the active slide is progressed to the next page on success. Don't want the user to be able to adjust the information that they supplied.
        setActiveSlide((prevState) => prevState + 2);
      } catch (err) {
        console.log(err);
        setSubmissionInProgress(false);
      }
    }
  };

  useEffect(() => {
    setGrantorPhoneNumberError("");
  }, [grantorPhoneNumber]);

  const addNewTab = () => {
    setRelatedAccounts([
      ...relatedAccounts,
      {
        relatedPartyType: "",
        relatedPartyTypeError: "",
        entityPercentOwned: "",
        entityPercentOwnedError: "",
        entityName: "",
        entityNameError: "",
        entityAddressLine1: "",
        entityAddressLine1Error: "",
        entityAddressLine2: "",
        entityAddressLine2Error: "",
        entityCity: "",
        entityCityError: "",
        entityState: "",
        entityStateError: "",
        entityPostalCode: "",
        entityPostalCodeError: "",
        entityTaxIDNumber: "",
        entityTaxIDNumberError: "",
        entityPhoneNumber: "",
        entityPhoneNumberError: "",
        entityStateOfIncorporation: "",
        entityStateOfIncorporationError: "",
        entityMailingAddressDifferent: "",
        entityMailingAddressDifferentError: "",
        entityMailingAddressLine1: "",
        entityMailingAddressLine1Error: "",
        entityMailingAddressLine2: "",
        entityMailingAddressLine2Error: "",
        entityMailingCity: "",
        entityMailingCityError: "",
        entityMailingState: "",
        entityMailingStateError: "",
        entityMailingPostalCode: "",
        entityMailingPostalCodeError: "",
        entityType: "",
        entityTypeError: "",
        entityControlPersonFirstName: "",
        entityControlPersonFirstNameError: "",
        entityControlPersonLastName: "",
        entityControlPersonLastNameError: "",
        entityControlPersonEmail: "",
        entityControlPersonEmailError: "",
        entityControlPersonIsUSBased: "",
        entityControlPersonIsUSBasedError: "",
        entityControlPersonIsUSCitizen: "",
        entityControlPersonIsUSCitizenError: "",
        entityControlPersonPIIAvailable: "",
        entityControlPersonPIIAvailableError: "",
        entityControlPersonAddressLine1: "",
        entityControlPersonAddressLine1Error: "",
        entityControlPersonAddressLine2: "",
        entityControlPersonAddressLine2Error: "",
        entityControlPersonCity: "",
        entityControlPersonCityError: "",
        entityControlPersonState: "",
        entityControlPersonStateError: "",
        entityControlPersonPostalCode: "",
        entityControlPersonPostalCodeError: "",
        entityControlPersonPhoneNumber: "",
        entityControlPersonPhoneNumberError: "",
        entityControlPersonTaxIDNumber: "",
        entityControlPersonTaxIDNumberError: "",
        entityControlPersonDOB: "",
        entityControlPersonDOBError: "",
        entityCertificateOfFormation: "",
        entityCertificateOfFormationError: "",
        entityProofOfAddress: "",
        entityProofOfAddressError: "",
        entityControlPersonPassportDocument: "",
        entityControlPersonPassportDocumentError: "",
        entityControlPersonProofOfAddress: "",
        entityControlPersonProofOfAddressError: "",
        entityIsTrustManagementCompany: "",
        entityIsTrustManagementCompanyError: "",
        entityHasAlternateSignatory: "",
        entityHasAlternateSignatoryError: "",
        entityAlternateSignatoryFullName: "",
        entityAlternateSignatoryFullNameError: "",
        entityAlternateSignatoryEmail: "",
        entityAlternateSignatoryEmailError: "",
        entityAlternateSignatoryTitle: "",
        entityAlternateSignatoryTitleError: "",
        entityControlPersonRole: "",
        entityControlPersonRoleError: "",
        entityNestedControlEntityTrusteeType: "",
        entityNestedControlEntityTrusteeTypeError: "",
        entityNestedControlEntityName: "",
        entityNestedControlEntityNameError: "",
        entityNestedControlEntityType: "",
        entityNestedControlEntityTypeError: "",
        entityNestedControlEntityPercentOwned: "",
        entityNestedControlEntityPercentOwnedError: "",
        entityNestedControlEntityStateOfIncorporation: "",
        entityNestedControlEntityStateOfIncorporationError: "",
        entityNestedControlEntityTaxIDNumber: "",
        entityNestedControlEntityTaxIDNumberError: "",
        entityNestedControlEntityPhoneNumber: "",
        entityNestedControlEntityPhoneNumberError: "",
        entityNestedControlEntityAddressLine1: "",
        entityNestedControlEntityAddressLine1Error: "",
        entityNestedControlEntityAddressLine2: "",
        entityNestedControlEntityAddressLine2Error: "",
        entityNestedControlEntityCity: "",
        entityNestedControlEntityCityError: "",
        entityNestedControlEntityState: "",
        entityNestedControlEntityStateError: "",
        entityNestedControlEntityPostalCode: "",
        entityNestedControlEntityPostalCodeError: "",
        entityNestedControlEntityIsTrustManagementCompany: "",
        entityNestedControlEntityIsTrustManagementCompanyError: "",
        entityNestedControlEntityAddressType: "",
        entityNestedControlEntityAddressTypeError: "",
        entityNestedControlEntityCertificateOfFormation: "",
        entityNestedControlEntityCertificateOfFormationError: "",
        entityNestedControlEntityProofOfAddress: "",
        entityNestedControlEntityProofOfAddressError: "",
        entityNestedControlEntityControlPersonFirstName: "",
        entityNestedControlEntityControlPersonFirstNameError: "",
        entityNestedControlEntityControlPersonLastName: "",
        entityNestedControlEntityControlPersonLastNameError: "",
        entityNestedControlEntityControlPersonEmail: "",
        entityNestedControlEntityControlPersonEmailError: "",
        entityNestedControlEntityControlPersonIsUSBased: "",
        entityNestedControlEntityControlPersonIsUSBasedError: "",
        entityNestedControlEntityControlPersonIsUSCitizen: "",
        entityNestedControlEntityControlPersonIsUSCitizenError: "",
        entityNestedControlEntityControlPersonPhoneNumber: "",
        entityNestedControlEntityControlPersonPhoneNumberError: "",
        entityNestedControlEntityControlPersonPIIAvailable: "",
        entityNestedControlEntityControlPersonPIIAvailableError: "",
        entityNestedControlEntityControlPersonAddressLine1: "",
        entityNestedControlEntityControlPersonAddressLine1Error: "",
        entityNestedControlEntityControlPersonAddressLine2: "",
        entityNestedControlEntityControlPersonAddressLine2Error: "",
        entityNestedControlEntityControlPersonCity: "",
        entityNestedControlEntityControlPersonCityError: "",
        entityNestedControlEntityControlPersonState: "",
        entityNestedControlEntityControlPersonStateError: "",
        entityNestedControlEntityControlPersonPostalCode: "",
        entityNestedControlEntityControlPersonPostalCodeError: "",
        entityNestedControlEntityControlPersonTaxIDNumber: "",
        entityNestedControlEntityControlPersonTaxIDNumberError: "",
        entityNestedControlEntityControlPersonDOB: "",
        entityNestedControlEntityControlPersonDOBError: "",
        entityNestedControlEntityControlPersonPassportDocument: "",
        entityNestedControlEntityControlPersonPassportDocumentError: "",
        entityNestedControlEntityControlPersonProofOfAddress: "",
        entityNestedControlEntityControlPersonProofOfAddressError: "",
        entityNestedControlEntityControlPersonAddressType: "",
        entityNestedControlEntityControlPersonAddressTypeError: "",
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        addressType: "",
        deliverableAddressLine1: "",
        deliverableAddressLine2: "",
        deliverableCity: "",
        deliverableState: "",
        deliverableCountry: "",
        deliverablePostalCode: "",
        deliverableAddressType: "",
        dateOfBirth: "",
        dateOfBirthError: "",
        idType: "",
        idNum: "",
        idNumError: "",
        domicileCountry: "",
        firstNameError: "",
        lastNameError: "",
        emailError: "",
        phoneNumberError: "",
        addressLine1Error: "",
        addressLine2Error: "",
        cityError: "",
        stateError: "",
        countryError: "",
        postalCodeError: "",
        deliverableAddressLine1Error: "",
        deliverableAddressLine2Error: "",
        deliverableCityError: "",
        deliverableStateError: "",
        deliverableCountryError: "",
        deliverablePostalCodeError: "",
        primaryAddressDeliverable: "",
        domicileCountryError: "",
        deliverableSelectionError: "",
        percentOwned: "",
        percentOwnedError: "",
        citizenship: "",
        citizenshipError: "",
      },
    ]);
    setActiveTab("Trustee #" + (relatedAccounts.length + 1).toString());
  };

  const beneficialOwnerAddNewTab = () => {
    setBeneficialOwnerRelatedAccounts([
      ...beneficialOwnerRelatedAccounts,
      {
        relatedPartyType: "",
        relatedPartyTypeError: "",
        entityPercentOwned: "",
        entityPercentOwnedError: "",
        entityName: "",
        entityNameError: "",
        entityAddressLine1: "",
        entityAddressLine1Error: "",
        entityAddressLine2: "",
        entityAddressLine2Error: "",
        entityCity: "",
        entityCityError: "",
        entityState: "",
        entityStateError: "",
        entityPostalCode: "",
        entityPostalCodeError: "",
        entityTaxIDNumber: "",
        entityTaxIDNumberError: "",
        entityPhoneNumber: "",
        entityPhoneNumberError: "",
        entityStateOfIncorporation: "",
        entityStateOfIncorporationError: "",
        entityMailingAddressDifferent: "",
        entityMailingAddressDifferentError: "",
        entityMailingAddressLine1: "",
        entityMailingAddressLine1Error: "",
        entityMailingAddressLine2: "",
        entityMailingAddressLine2Error: "",
        entityMailingCity: "",
        entityMailingCityError: "",
        entityMailingState: "",
        entityMailingStateError: "",
        entityMailingPostalCode: "",
        entityMailingPostalCodeError: "",
        entityType: "",
        entityTypeError: "",
        entityControlPersonFirstName: "",
        entityControlPersonFirstNameError: "",
        entityControlPersonLastName: "",
        entityControlPersonLastNameError: "",
        entityControlPersonEmail: "",
        entityControlPersonEmailError: "",
        entityControlPersonIsUSBased: "",
        entityControlPersonIsUSBasedError: "",
        entityControlPersonIsUSCitizen: "",
        entityControlPersonIsUSCitizenError: "",
        entityControlPersonPIIAvailable: "",
        entityControlPersonPIIAvailableError: "",
        entityControlPersonAddressLine1: "",
        entityControlPersonAddressLine1Error: "",
        entityControlPersonAddressLine2: "",
        entityControlPersonAddressLine2Error: "",
        entityControlPersonCity: "",
        entityControlPersonCityError: "",
        entityControlPersonState: "",
        entityControlPersonStateError: "",
        entityControlPersonPostalCode: "",
        entityControlPersonPostalCodeError: "",
        entityControlPersonPhoneNumber: "",
        entityControlPersonPhoneNumberError: "",
        entityControlPersonTaxIDNumber: "",
        entityControlPersonTaxIDNumberError: "",
        entityControlPersonDOB: "",
        entityControlPersonDOBError: "",
        entityCertificateOfFormation: "",
        entityCertificateOfFormationError: "",
        entityProofOfAddress: "",
        entityProofOfAddressError: "",
        entityControlPersonPassportDocument: "",
        entityControlPersonPassportDocumentError: "",
        entityControlPersonProofOfAddress: "",
        entityControlPersonProofOfAddressError: "",
        entityIsTrustManagementCompany: "",
        entityIsTrustManagementCompanyError: "",
        entityHasAlternateSignatory: "",
        entityHasAlternateSignatoryError: "",
        entityAlternateSignatoryFullName: "",
        entityAlternateSignatoryFullNameError: "",
        entityAlternateSignatoryEmail: "",
        entityAlternateSignatoryEmailError: "",
        entityAlternateSignatoryTitle: "",
        entityAlternateSignatoryTitleError: "",
        entityControlPersonRole: "",
        entityControlPersonRoleError: "",
        entityNestedControlEntityTrusteeType: "",
        entityNestedControlEntityTrusteeTypeError: "",
        entityNestedControlEntityName: "",
        entityNestedControlEntityNameError: "",
        entityNestedControlEntityType: "",
        entityNestedControlEntityTypeError: "",
        entityNestedControlEntityPercentOwned: "",
        entityNestedControlEntityPercentOwnedError: "",
        entityNestedControlEntityStateOfIncorporation: "",
        entityNestedControlEntityStateOfIncorporationError: "",
        entityNestedControlEntityTaxIDNumber: "",
        entityNestedControlEntityTaxIDNumberError: "",
        entityNestedControlEntityPhoneNumber: "",
        entityNestedControlEntityPhoneNumberError: "",
        entityNestedControlEntityAddressLine1: "",
        entityNestedControlEntityAddressLine1Error: "",
        entityNestedControlEntityAddressLine2: "",
        entityNestedControlEntityAddressLine2Error: "",
        entityNestedControlEntityCity: "",
        entityNestedControlEntityCityError: "",
        entityNestedControlEntityState: "",
        entityNestedControlEntityStateError: "",
        entityNestedControlEntityPostalCode: "",
        entityNestedControlEntityPostalCodeError: "",
        entityNestedControlEntityIsTrustManagementCompany: "",
        entityNestedControlEntityIsTrustManagementCompanyError: "",
        entityNestedControlEntityAddressType: "",
        entityNestedControlEntityAddressTypeError: "",
        entityNestedControlEntityCertificateOfFormation: "",
        entityNestedControlEntityCertificateOfFormationError: "",
        entityNestedControlEntityProofOfAddress: "",
        entityNestedControlEntityProofOfAddressError: "",
        entityNestedControlEntityControlPersonFirstName: "",
        entityNestedControlEntityControlPersonFirstNameError: "",
        entityNestedControlEntityControlPersonLastName: "",
        entityNestedControlEntityControlPersonLastNameError: "",
        entityNestedControlEntityControlPersonEmail: "",
        entityNestedControlEntityControlPersonEmailError: "",
        entityNestedControlEntityControlPersonIsUSBased: "",
        entityNestedControlEntityControlPersonIsUSBasedError: "",
        entityNestedControlEntityControlPersonIsUSCitizen: "",
        entityNestedControlEntityControlPersonIsUSCitizenError: "",
        entityNestedControlEntityControlPersonPhoneNumber: "",
        entityNestedControlEntityControlPersonPhoneNumberError: "",
        entityNestedControlEntityControlPersonPIIAvailable: "",
        entityNestedControlEntityControlPersonPIIAvailableError: "",
        entityNestedControlEntityControlPersonAddressLine1: "",
        entityNestedControlEntityControlPersonAddressLine1Error: "",
        entityNestedControlEntityControlPersonAddressLine2: "",
        entityNestedControlEntityControlPersonAddressLine2Error: "",
        entityNestedControlEntityControlPersonCity: "",
        entityNestedControlEntityControlPersonCityError: "",
        entityNestedControlEntityControlPersonState: "",
        entityNestedControlEntityControlPersonStateError: "",
        entityNestedControlEntityControlPersonPostalCode: "",
        entityNestedControlEntityControlPersonPostalCodeError: "",
        entityNestedControlEntityControlPersonTaxIDNumber: "",
        entityNestedControlEntityControlPersonTaxIDNumberError: "",
        entityNestedControlEntityControlPersonDOB: "",
        entityNestedControlEntityControlPersonDOBError: "",
        entityNestedControlEntityControlPersonPassportDocument: "",
        entityNestedControlEntityControlPersonPassportDocumentError: "",
        entityNestedControlEntityControlPersonProofOfAddress: "",
        entityNestedControlEntityControlPersonProofOfAddressError: "",
        entityNestedControlEntityControlPersonAddressType: "",
        entityNestedControlEntityControlPersonAddressTypeError: "",
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        addressType: "",
        deliverableAddressLine1: "",
        deliverableAddressLine2: "",
        deliverableCity: "",
        deliverableState: "",
        deliverableCountry: "",
        deliverablePostalCode: "",
        deliverableAddressType: "",
        dateOfBirth: "",
        dateOfBirthError: "",
        idType: "",
        idNum: "",
        idNumError: "",
        domicileCountry: "",
        firstNameError: "",
        lastNameError: "",
        emailError: "",
        phoneNumberError: "",
        addressLine1Error: "",
        addressLine2Error: "",
        cityError: "",
        stateError: "",
        countryError: "",
        postalCodeError: "",
        deliverableAddressLine1Error: "",
        deliverableAddressLine2Error: "",
        deliverableCityError: "",
        deliverableStateError: "",
        deliverableCountryError: "",
        deliverablePostalCodeError: "",
        primaryAddressDeliverable: "",
        domicileCountryError: "",
        deliverableSelectionError: "",
        percentOwned: "",
        percentOwnedError: "",
        citizenship: "",
        citizenshipError: "",
      },
    ]);
    setActiveBeneficialOwnerTab(
      "Beneficial Owner #" +
        (beneficialOwnerRelatedAccounts.length + 1).toString()
    );
  };

  const handleRemoveFields = (index) => {
    const nextIdx = index === 0 ? 0 : index - 1;
    setActiveTab("Trustee #" + (nextIdx + 1).toString());
    setRelatedAccounts((relatedAccounts) =>
      relatedAccounts.filter((elem, idx) => idx !== index)
    );
  };

  const beneficialOwnerHandleRemoveFields = (index) => {
    const nextIdx = index === 0 ? 0 : index - 1;
    setActiveBeneficialOwnerTab(
      "Beneficial Owner #" + (nextIdx + 1).toString()
    );
    setBeneficialOwnerRelatedAccounts((beneficialOwnerRelatedAccounts) =>
      beneficialOwnerRelatedAccounts.filter((elem, idx) => idx !== index)
    );
  };

  const yesNoOptions = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  return (
    <div className="row g-0 w-100 add-account-wizard-container">
      <div className="col-12 add-account-outer-column-padded">
        <div className="row g-0 w-100 mb-2">
          <div className="col-12">
            <GrantorInformation
              id={accountID}
              grantorFirstName={grantorFirstName}
              setGrantorFirstName={setGrantorFirstName}
              grantorFirstNameError={grantorFirstNameError}
              setGrantorFirstNameError={setGrantorFirstNameError}
              grantorLastName={grantorLastName}
              setGrantorLastName={setGrantorLastName}
              grantorLastNameError={grantorLastNameError}
              setGrantorLastNameError={setGrantorLastNameError}
              grantorEmail={grantorEmail}
              setGrantorEmail={setGrantorEmail}
              grantorEmailError={grantorEmailError}
              setGrantorEmailError={setGrantorEmailError}
              grantorPhoneNumber={grantorPhoneNumber}
              setGrantorPhoneNumber={setGrantorPhoneNumber}
              grantorPhoneNumberError={grantorPhoneNumberError}
              setGrantorPhoneNumberError={setGrantorPhoneNumberError}
              grantorDomicileCountry={grantorDomicileCountry}
              setGrantorDomicileCountry={setGrantorDomicileCountry}
              grantorDomicileCountryError={grantorDomicileCountryError}
              setGrantorDomicileCountryError={setGrantorDomicileCountryError}
              grantorAddressLine1={grantorAddressLine1}
              setGrantorAddressLine1={setGrantorAddressLine1}
              grantorAddressLine1Error={grantorAddressLine1Error}
              setGrantorAddressLine1Error={setGrantorAddressLine1Error}
              grantorAddressLine2={grantorAddressLine2}
              setGrantorAddressLine2={setGrantorAddressLine2}
              grantorAddressLine2Error={grantorAddressLine2Error}
              setGrantorAddressLine2Error={setGrantorAddressLine2Error}
              grantorState={grantorState}
              setGrantorState={setGrantorState}
              grantorStateError={grantorStateError}
              setGrantorStateError={setGrantorStateError}
              grantorCity={grantorCity}
              setGrantorCity={setGrantorCity}
              grantorCityError={grantorCityError}
              setGrantorCityError={setGrantorCityError}
              grantorCountry={grantorCountry}
              setGrantorCountry={setGrantorCountry}
              grantorCountryError={grantorCountryError}
              setGrantorCountryError={setGrantorCountryError}
              grantorPostalCode={grantorPostalCode}
              setGrantorPostalCode={setGrantorPostalCode}
              grantorPostalCodeError={grantorPostalCodeError}
              setGrantorPostalCodeError={setGrantorPostalCodeError}
              grantorDOB={grantorDOB}
              setGrantorDOB={setGrantorDOB}
              grantorDOBError={grantorDOBError}
              setGrantorDOBError={setGrantorDOBError}
              grantorIDNumber={grantorIDNumber}
              setGrantorIDNumber={setGrantorIDNumber}
              grantorIDNumberError={grantorIDNumberError}
              setGrantorIDNumberError={setGrantorIDNumberError}
              supplyPercentOwned={supplyPercentOwned}
              setGrantorCitizenshipError={setGrantorCitizenshipError}
              setGrantorPercentOwnedError={setGrantorPercentOwnedError}
              setGrantorCitizenship={setGrantorCitizenship}
              grantorCitizenship={grantorCitizenship}
              grantorCitizenshipError={grantorCitizenshipError}
              grantorPercentOwned={grantorPercentOwned}
              setGrantorPercentOwned={setGrantorPercentOwned}
            />
          </div>
        </div>
        {accountType === "TRUST" && (
          <React.Fragment>
            <div className="row g-0 w-100 mb-3">
              <span className="add-account-step-subheader">
                Alternate Signatory Information
              </span>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col pe-0">
                <SelectField
                  value={hasAlternateSignatory}
                  setValue={setHasAlternateSignatory}
                  valueError={hasAlternateSignatoryError}
                  setValueError={setHasAlternateSignatoryError}
                  selectOptions={yesNoOptions}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={false}
                  shrink={true}
                  id={"has-alternate-signatory-select"}
                  label={
                    "Is there an alternate signatory? If no, the grantor will be used."
                  }
                />
              </div>
            </div>
            {hasAlternateSignatory === "Yes" && (
              <React.Fragment>
                <div className="row g-0 w-100 mb-3">
                  <div className="col pe-2">
                    <StyledTextInputField
                      value={alternateSignatoryFullName}
                      setValue={setAlternateSignatoryFullName}
                      setValueError={setAlternateSignatoryFullNameError}
                      valueError={alternateSignatoryFullNameError}
                      label={"Alternate Signatory Full Name"}
                      required={true}
                      fullWidth={true}
                      size={"small"}
                      disabled={false}
                      shrink={true}
                      id={"alternate-signatory-full-name-input"}
                    />
                  </div>
                  <div className="col ps-2 pe-0">
                    <StyledTextInputField
                      value={alternateSignatoryEmail}
                      setValue={setAlternateSignatoryEmail}
                      setValueError={setAlternateSignatoryEmailError}
                      valueError={alternateSignatoryEmailError}
                      label={"Alternate Signatory Email"}
                      required={true}
                      fullWidth={true}
                      size={"small"}
                      disabled={false}
                      shrink={true}
                      id={"alternate-signatory-email-input"}
                    />
                  </div>
                </div>
                <div className="row g-0 w-100 mb-3">
                  <div className="col pe-0">
                    <StyledTextInputField
                      value={alternateSignatoryTitle}
                      setValue={setAlternateSignatoryTitle}
                      setValueError={setAlternateSignatoryTitleError}
                      valueError={alternateSignatoryTitleError}
                      label={"Alternate Signatory Title"}
                      required={true}
                      fullWidth={true}
                      size={"small"}
                      disabled={false}
                      shrink={true}
                      id={"alternate-signatory-title-input"}
                    />
                  </div>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}

        <div className="row g-0 w-100 mb-1">
          <span className="add-account-step-subheader">
            Trustee Information
          </span>
        </div>
        <TrusteeSection
          accountType={accountType}
          supplyPercentOwned={supplyPercentOwned}
          shouldRenderTrusteeSection={shouldRenderTrusteeSection}
          showTrusteeSection={showTrusteeSection}
          setShowTrusteeSection={setShowTrusteeSection}
          mountedStyle={mountedStyle}
          unmountedStyle={unmountedStyle}
          answer={hasTrustees}
          setAnswer={setHasTrustees}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          addNewTab={supplyPercentOwned ? false : addNewTab}
          accountID={accountID}
          relatedAccounts={relatedAccounts}
          setRelatedAccounts={setRelatedAccounts}
          handleRemoveFields={handleRemoveFields}
          setActiveSlide={setActiveSlide}
          hideButton={hideButton}
          setHideButton={setHideButton}
          hideAlterButtons={hideAlterButtons}
          setHideAlterButtons={setHideAlterButtons}
          hasTrusteesError={hasTrusteesError}
          setHasTrusteesError={setHasTrusteesError}
          grantorFirstName={grantorFirstName}
          setGrantorFirstName={setGrantorFirstName}
          grantorFirstNameError={grantorFirstNameError}
          setGrantorFirstNameError={setGrantorFirstNameError}
          grantorLastName={grantorLastName}
          setGrantorLastName={setGrantorLastName}
          grantorLastNameError={grantorLastNameError}
          setGrantorLastNameError={setGrantorLastNameError}
          grantorEmail={grantorEmail}
          setGrantorEmail={setGrantorEmail}
          grantorEmailError={grantorEmailError}
          setGrantorEmailError={setGrantorEmailError}
          grantorPhoneNumber={grantorPhoneNumber}
          setGrantorPhoneNumber={setGrantorPhoneNumber}
          grantorPhoneNumberError={grantorPhoneNumberError}
          setGrantorPhoneNumberError={setGrantorPhoneNumberError}
          grantorDomicileCountry={grantorDomicileCountry}
          setGrantorDomicileCountry={setGrantorDomicileCountry}
          grantorDomicileCountryError={grantorDomicileCountryError}
          setGrantorDomicileCountryError={setGrantorDomicileCountryError}
          grantorAddressLine1={grantorAddressLine1}
          setGrantorAddressLine1={setGrantorAddressLine1}
          grantorAddressLine1Error={grantorAddressLine1Error}
          setGrantorAddressLine1Error={setGrantorAddressLine1Error}
          grantorAddressLine2={grantorAddressLine2}
          setGrantorAddressLine2={setGrantorAddressLine2}
          grantorAddressLine2Error={grantorAddressLine2Error}
          setGrantorAddressLine2Error={setGrantorAddressLine2Error}
          grantorState={grantorState}
          setGrantorState={setGrantorState}
          grantorStateError={grantorStateError}
          setGrantorStateError={setGrantorStateError}
          grantorCity={grantorCity}
          setGrantorCity={setGrantorCity}
          grantorCityError={grantorCityError}
          setGrantorCityError={setGrantorCityError}
          grantorCountry={grantorCountry}
          setGrantorCountry={setGrantorCountry}
          grantorCountryError={grantorCountryError}
          setGrantorCountryError={setGrantorCountryError}
          grantorPostalCode={grantorPostalCode}
          setGrantorPostalCode={setGrantorPostalCode}
          grantorPostalCodeError={grantorPostalCodeError}
          setGrantorPostalCodeError={setGrantorPostalCodeError}
          grantorDOB={grantorDOB}
          setGrantorDOB={setGrantorDOB}
          grantorDOBError={grantorDOBError}
          setGrantorDOBError={setGrantorDOBError}
          grantorIDNumber={grantorIDNumber}
          setGrantorIDNumber={setGrantorIDNumber}
          grantorIDNumberError={grantorIDNumberError}
          setGrantorIDNumberError={setGrantorIDNumberError}
          grantorPercentOwned={grantorPercentOwned}
          setGrantorPercentOwned={setGrantorPercentOwned}
          grantorPercentOwnedError={grantorPercentOwnedError}
          setGrantorPercentOwnedError={setGrantorPercentOwnedError}
          grantorCitizenship={grantorCitizenship}
          setGrantorCitizenship={setGrantorCitizenship}
          grantorCitizenshipError={grantorCitizenshipError}
          setGrantorCitizenshipError={setGrantorCitizenshipError}
        />
        <BeneficialOwnerSection
          accountType={accountType}
          shouldRenderBeneficialOwnerSection={
            shouldRenderBeneficialOwnerSection
          }
          showBeneficialOwnerSection={showBeneficialOwnerSection}
          setShowBeneficialOwnerSection={setShowBeneficialOwnerSection}
          mountedStyle={mountedStyle}
          unmountedStyle={unmountedStyle}
          answer={hasBeneficialOwners}
          setAnswer={setHasBeneficialOwners}
          activeTab={activeBeneficialOwnerTab}
          setActiveTab={setActiveBeneficialOwnerTab}
          addNewTab={
            beneficialOwnerHideButton ? false : beneficialOwnerAddNewTab
          }
          relatedAccounts={beneficialOwnerRelatedAccounts}
          setRelatedAccounts={setBeneficialOwnerRelatedAccounts}
          handleRemoveFields={beneficialOwnerHandleRemoveFields}
          setActiveSlide={setActiveSlide}
          hideButton={beneficialOwnerHideButton}
          setHideButton={setBeneficialOwnerHideButton}
          hideAlterButtons={hideAlterButtons}
          setHideAlterButtons={setHideAlterButtons}
          hasBeneficialOwnersError={hasBeneficialOwnersError}
          setHasBeneficialOwnersError={setHasBeneficialOwnersError}
          supplyPercentOwned={supplyPercentOwned}
        />
        {!hideButton && (
          <div className="row g-0 w-100">
            <div className="col">
              <div className="d-flex">
                <button
                  type="button"
                  class="btn btn-lg btn-class"
                  onClick={() => {
                    submit();
                  }}
                >
                  {submissionInProgress && (
                    <div className="row g-0 w-100">
                      <div className="col d-flex align-items-center">
                        <span className="justify-self-start align-self-center">
                          Submit
                        </span>
                        <span
                          class="ms-2 spinner-border spinner-border-sm justify-self-end align-self-center"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </div>
                    </div>
                  )}
                  {!submissionInProgress && <span>Submit</span>}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default TrustAccountRelatedPartyInformation;
