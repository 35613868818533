import React, { useState, useEffect, useContext } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Paper,
  TableContainer,
  TablePagination,
  TextField,
  CircularProgress,
} from '@mui/material';
import axios from 'axios';
import Checkbox from "@mui/material/Checkbox";
import Dropdown from 'react-bootstrap/Dropdown';
import Header from "../Components/GlobalComponents/Header.js";
import { UserContext } from "../GlobalContexts/UserContext.js";
import {
  resendMagicLink,
} from "../Api/DocumentsApi";
import { Button, Modal, Form, ListGroup } from 'react-bootstrap';
import { HOST } from "../host-config.js";
import { getRequestHeaders } from "../GlobalFunctions/RequestHeaders.js";
import { Auth } from "aws-amplify";
import { Snackbar } from "@mui/material";

function investmentStatusFormatter(value) {
  if (value !== undefined && value !== null) {
    let investmentStatusMap = {
      P: "Pending Approval",
      PD: "Pending Sub Docs",
      PF: "Pending Funding",
      F: "Funded",
      A: "Approved",
      R: "Rejected",
    };
    return investmentStatusMap[value.toString()];
  }
  return "";
}


function formatDateTime(dateString) {
  const date = new Date(dateString);

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  return date.toLocaleString('en-US', options);
}




const AdminDashboard = () => {
  // Example usage
  const humanReadableDate = formatDateTime('2023-06-06T17:39:39.552423Z');
  console.log(humanReadableDate);
  const [email, setEmail] = useState(null);

  /*async function loadUser() {
    const sessionInfo = await Auth.currentSession();
    let user = sessionInfo.idToken.payload.email;
    setEmail(user);
    console.log(user);
  }
  
  loadUser();*/
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [deal, setDeal] = useState("SLAI1");
  const [dealData, setDealData] = useState({});
  const [linkResent, setLinkResent] = useState(false);

  const resend = async (code) => {
    setLinkResent(true);
    const resp = await resendMagicLink(code);
  };

  const formatBank = (obj) => {  
    const addr = obj["bank_address"]
    const street = addr["street"]
    const street_2 = addr["street_2"]
    const city = addr["city"]
    const state = addr["state"]
    const zip = addr["zip_code"]


    const htmlString =     `Routing: ${obj["routing_number"]}<br />
    Account: ${obj["account_number"]}<br/ >
    Bank Name: ${obj["bank_name"]}<br />
    ${street} ${street_2}<br />
    ${city} ${state} ${zip}`;
    return <div dangerouslySetInnerHTML={{ __html: htmlString }} />

  }

  useEffect(async () => {
    async function call() {
      const config = await getRequestHeaders();
      return axios.post(
        HOST + "/client_dashboard",
        { fund_name: "SLAI1", options: ["investments", "documents"] },
        config
      );
    }

    const resp = await call();
    setDealData(resp.data.msg);
  }, []);
  return (
    <>
  {/*<SnackBar
          snackbarState={linkResent}
          setSnackbarState={setLinkResent}
          vertical={"top"}
          horizontal={"right"}
          duration={5000}
          sxProps={{ paddingLeft: "3px" }}
          severity={"success"}
          message={"Magic link resent."}
  />*/}
      <Header activePage={"dashboard"}></Header>
      <div className="mt-5 pt-5"></div>
      <div className="container-xxl contain mt-xxl-2 mt-xl-2 mt-lg-2 mt-md-2 mt-sm-2 mt-4 pb-5">
        <h2>Deal dashboard</h2>
        <br />

        {deal && Object.keys(dealData).length !== 0 && (
          <>
            <h3>Deal info</h3>
            <ul>
              <li>
                Commitments: ${dealData.agg_data.signed.toLocaleString("en-US")}{" "}
              </li>
              <li>
                Funded: ${dealData.agg_data.funded.toLocaleString("en-US")}{" "}
              </li>
            </ul>
            <h3>Accounts</h3>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                    <TableCell>Investor Name</TableCell>
                    <TableCell>Investor Email</TableCell>
                      <TableCell>Account Name</TableCell>
                      <TableCell>Account Type</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Wire Instructions</TableCell>
                      <TableCell>Doc Link</TableCell>
                      <TableCell>Signature Link</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dealData.investments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                      <TableRow key={row.id}>
                        <TableCell>{row.investor_name}</TableCell>
                        <TableCell>{row.investor_email}</TableCell>
                        <TableCell>{row.account_name}</TableCell>
                        <TableCell>{row.ent_type ? row.ent_type : 'IND'}</TableCell>
                        <TableCell>{row.investment_status ? row.investment_status : 'Uninvested'}</TableCell>
                        <TableCell>{row.investment_amount ? '$'+row.investment_amount.toLocaleString("en-US"): "N/A"}</TableCell>
                        <TableCell>{row.investment_date ? formatDateTime(row.investment_date) : "N/A"}</TableCell>
                        <TableCell>{row.sydecar_wire_ins ? formatBank(row.sydecar_wire_ins) : 'No wire instructions'}</TableCell>
                        <TableCell> {row.subdoc_link === "Signed doc not available" ? row.subdoc_link : <Button variant="primary" target="_blank" href={row.subdoc_link}>View</Button>}</TableCell>
                        <TableCell>
                          {
                        (row.status === "PD" && row.magic_link !== '') ? 
                        <div dangerouslySetInnerHTML={{ __html: `<a href="https://sunshinelake.investwithaqua.com/magiclink/${row.magic_link}">https://sunshinelake.investwithaqua.com/magiclink/${row.magic_link}</a>` }} /> :
                        <>N/A</>}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  component="div"
                  count={dealData.investments.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />

              </TableContainer>
            <br />
            <h3>Fund Documents</h3>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Category</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dealData.documents.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.category}</TableCell>
                      <TableCell>
                        {" "}
                        {row.url == "" ? <></> : <Button variant="primary" target="_blank" href={row.url}>
                          View
                        </Button>}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </div>
    </>
  );
};

export default AdminDashboard;
